/** @jsxImportSource @emotion/react */
import CopyrightIcon from '@mui/icons-material/Copyright'
import {Box, Divider, Stack, Typography, useMediaQuery, useTheme, Unstable_Grid2 as Grid, Button} from '@mui/material'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import ftLogo from '../../assets/ft_logo.svg'
import ihqLogo from '../../assets/ihq_dark.svg'
import {LinkedInIcon} from '../../assets/linkedInIcon'
import {TwitterIcon} from '../../assets/twitterIcon'
import ContactUsDrawer from '../contactUsForm/contactUsDrawer'
import CustomLink from '../customMUI/customLink/customLink'
import LanguageMenu from '../languageMenu/languageMenu'

import Office from './office'
import styles from './styles'

const CompanyFooter = (): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [openContact, setOpenContact] = useState<boolean>(false)

  const offices = [
    {
      country: t('shared:footer.offices.countryUK'),
      phone: '+44 20 7636 4400'
    },
    {
      country: t('shared:footer.offices.countrySP'),
      phone: '+34 932 718 523'
    },
    {
      country: t('shared:footer.offices.countryUS'),
      phone: '+1 (347) 618 2568'
    }
  ]

  return (
    <Box component="footer" sx={styles.root}>
      <Stack direction={{sm: 'row', xs: 'column-reverse'}} justifyContent="space-between" spacing={{xs: 6, sm: 0}}>
        <Stack>
          <Typography color="primary" sx={styles.title} variant="h1">
            {t('shared:footer.getInTouch')}
          </Typography>
          <Grid container smOffset="auto" spacing={{xs: 2, lg: 10}} xsOffset={0}>
            {offices.map((office, index) => (
              <Grid key={index} lg="auto" sx={styles.officeContainer}>
                <Office key={index} office={office} />
              </Grid>
            ))}
          </Grid>
          <Stack direction={'row'} spacing={1} sx={styles.contactUsContainer}>
            <Button color="primary" sx={styles.contactUsButton} onClick={() => setOpenContact(true)}>
              {t('shared:button.letsChat')}
            </Button>
            <a data-testid="twitter-link" href="https://twitter.com/WeAreIntentHQ">
              <TwitterIcon />
            </a>
            <a data-testid="linkedin-link" href="https://www.linkedin.com/company/intenthq/">
              <LinkedInIcon />
            </a>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between" spacing={3}>
          <img css={styles.logo(isExtraSmall)} src={ftLogo} />
          <img css={styles.logo(isExtraSmall)} src={ihqLogo} />
        </Stack>
      </Stack>
      <Divider sx={styles.divider} />
      <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between" spacing={{xs: 3, sm: 0}}>
        <Stack direction="row" justifyContent={{xs: 'space-between', sm: 'flex-start'}}>
          <CustomLink classname={{alignSelf: 'center'}} link="https://intenthq.com" text="intenthq.com" variant="h6" />
          <LanguageMenu
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            color="secondary"
            margin="-13px"
            transformOrigin={{horizontal: 'left', vertical: 'bottom'}}
            variant="text"
          />
        </Stack>
        <Stack alignItems={{xs: 'flex-start', sm: 'center'}} direction={{xs: 'column', md: 'row'}} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={0.5}>
            <CopyrightIcon sx={styles.copyrightIcon} />
            <Typography sx={styles.smallText}>
              Intent HQ {moment().year()} {t('shared:footer.rights')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomLink
              classname={styles.smallText}
              color="text.primary"
              link="https://intenthq.com/terms-and-conditions/"
              text={t('shared:footer.termsConditions')}
            />
            <CustomLink
              classname={styles.smallText}
              color="text.primary"
              link="https://intenthq.com/privacy-policy/"
              text={t('shared:footer.privacyPolicy')}
            />
          </Stack>
        </Stack>
      </Stack>
      <ContactUsDrawer open={openContact} onClose={() => setOpenContact(false)} />
    </Box>
  )
}

export default CompanyFooter
