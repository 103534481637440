/** @jsxImportSource @emotion/react */
import {useMediaQuery, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomDrawer} from '../customMUI/customDrawer/customDrawer'

import {ContactUsForm} from './contactUsForm'

interface IContactUsFormProps {
  open: boolean
  onClose: () => void
}

const ContactUsDrawer = ({open, onClose}: IContactUsFormProps): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const handleClose = (): void => {
    onClose()
  }

  return (
    <CustomDrawer
      isMobileForm
      drawerAnchor={`${isMobile ? 'top' : 'bottom'}`}
      open={open}
      title={t('insightCatalogue:contactForm.title')}
      onClose={handleClose}>
      <ContactUsForm />
    </CustomDrawer>
  )
}

export default ContactUsDrawer
