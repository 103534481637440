const styles = {
  root: {
    backgroundColor: 'white',
    clipPath: 'polygon(10% 0%, 100% 0%, 100% 86%, 90% 100%, 0% 100%, 0% 14%)',
    height: '360px',
    margin: 'auto',
    position: 'relative' as const,
    textAlign: 'center' as const,
    verticalAlign: 'middle',
    width: '500px'
  },
  error: {
    fontSize: '10rem',
    fontFamily: 'BlenderPro_medium',
    paddingTop: '50px'
  },
  lightText: {
    fontFamily: '"noto_sansregular"'
  },
  children: {
    marginTop: '44px'
  }
}

export default styles
