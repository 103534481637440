export const MuiButton = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained' as const
  },
  styleOverrides: {
    root: {
      borderRadius: '8px',
      letterSpacing: '0.1rem'
    },
    contained: {
      color: 'white',
      fontFamily: 'BlenderPro_medium',
      lineHeight: '1',
      '&:hover': {
        color: 'white'
      }
    },
    containedSecondary: {
      backgroundColor: '#0F072A',
      '&:hover': {
        backgroundColor: '#291274'
      }
    },
    outlined: {
      background: '#fff',
      borderColor: '#4B455F',
      color: '#4B455F',
      fontFamily: 'BlenderPro_medium',
      lineHeight: '1',
      '&:hover': {
        background: '#fff',
        borderColor: '#471FC7',
        color: '#471FC7'
      }
    },
    textSecondary: {
      color: '#0F072A',
      '&:hover': {
        color: '#291274'
      }
    },
    sizeLarge: {
      fontSize: '2.2rem',
      lineHeight: '1.2'
    },
    sizeMedium: {
      fontSize: '1.6rem',
      lineHeight: '1.25'
    },
    sizeSmall: {
      fontSize: '1.4rem',
      lineHeight: '1.3'
    }
  }
}
