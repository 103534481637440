/** @jsxImportSource @emotion/react */
import {Box, Divider, Unstable_Grid2 as Grid, Skeleton, Stack} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useTranslation} from 'react-i18next'

import apiClient from '../../backendApi/api'
import {type InsightCatalogueItemDto} from '../../backendApi/models'

import ReportDetailsHeader from './reportDetailsHeader/reportDetailsHeader'
import {ReportDetailsInfo} from './reportDetailsInfo/reportDetailsInfo'
import ReportDetailsSidebar from './reportDetailsSidebar/reportDetailsSidebar'
import styles from './styles'

interface IReportDetailsProps {
  insightId: string
  onClose: () => void
}

export const ReportDetails = ({insightId, onClose}: IReportDetailsProps): JSX.Element => {
  const {i18n} = useTranslation()

  const {data: details} = useQuery<InsightCatalogueItemDto, Error>({
    queryKey: ['reportsDetails', insightId, i18n.language],
    queryFn: async () => await apiClient.insightsApi.insightsFindOne({id: insightId})
  })

  return (
    <Box>
      <ReportDetailsHeader text={details?.insightMetadata.title} onClose={onClose} />
      <Divider sx={styles.divider} />
      <Grid container direction={{md: 'row-reverse'}} justifyContent="center" sx={styles.body}>
        <Grid md={4} sx={styles.sidebar}>
          <ReportDetailsSidebar details={details} />
        </Grid>
        <Grid md={8} xs={12}>
          {details ? <ReportDetailsInfo details={details} /> : <Loader />}
        </Grid>
      </Grid>
    </Box>
  )
}

const Loader = () => (
  <Stack spacing={1}>
    <Skeleton
      sx={{
        fontSize: '3rem',
        width: {xs: '75px', sm: '125px', md: '25%'}
      }}
      variant="text"
    />
    <Skeleton height={35} sx={{width: {xs: '300px', sm: '500px', md: '100%'}}} variant="rectangular" />
    <Skeleton
      sx={{
        fontSize: '2.5rem',
        width: {xs: '150px', sm: '250px', md: '50%'}
      }}
      variant="text"
    />
    <Skeleton
      height={60}
      sx={{
        fontSize: '2.5rem',
        width: {xs: '90px', sm: '150px', md: '30%'}
      }}
      variant="rectangular"
      width="30%"
    />
  </Stack>
)
