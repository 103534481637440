/** @jsxImportSource @emotion/react */
import {Unstable_Grid2 as Grid, Stack} from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {InsightMetadataDto} from '../../../../backendApi'
import MarkdownViewer from '../../../markdownViewer/markdownViewer'

import {styles} from './styles'

export const ReportDescription = ({insight}: {insight: InsightMetadataDto}) => {
  const {t} = useTranslation()

  return (
    <>
      <Typography component="div" variant="body2">
        {insight.detailedDescription !== undefined && <MarkdownViewer doc={insight.detailedDescription} />}
      </Typography>
      <Stack direction={{xs: 'column', lg: 'row'}} spacing={{xs: 2, lg: 6}} sx={styles.moreInfoContainer}>
        <Stack direction="row" spacing={1}>
          <Typography sx={[styles.text, styles.textUppercase]} variant="body2">
            {t('insightCatalogue:insightsDetail.createdBy')}
          </Typography>
          <Typography sx={[styles.text, styles.textUppercase, styles.textBold]} variant="body2">
            {insight.insightMaker}
          </Typography>
        </Stack>
        <Stack alignSelf="flex-start" direction={{xs: 'column', sm: 'row'}} spacing={{sm: 1}}>
          <Typography sx={[styles.text, styles.textUppercase]} variant="body2">
            {t('insightCatalogue:insightsDetail.dataSource')}
          </Typography>
          <Grid container>
            {insight?.industry?.map((industry: string) => (
              <Grid key={industry}>
                <Typography sx={styles.tags} variant="body2">
                  {industry}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </>
  )
}
