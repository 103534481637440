export const MuiTooltip = {
  styleOverrides: {
    root: {
      fontFamily: 'noto_sansregular',
      fontsize: '1rem',
      letterSpacing: '0',
      lineHeight: '0.14'
    },
    popper: {
      '& .MuiTooltip-arrow': {
        color: 'black'
      },

      '& .MuiTooltip-tooltip': {
        backgroundColor: 'black'
      }
    }
  }
}
