export const MuiListItemButton = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: 'transparent'
      },
      '&.Mui-selected:hover': {
        backgroundColor: 'transparent'
      },
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}
