const styles = {
  root: {
    background: 'white',
    padding: {
      xs: '40px 30px',
      sm: '40px 50px',
      md: '40px 1.6%',
      xl: '40px 2.8%'
    },
    '@media (max-width: 1440px) and (min-width: 1397px)': {
      padding: {
        lg: '40px max(24px, calc((100% - 1274px)/2))'
      }
    }
  },
  officeContainer: {
    paddingLeft: '0px'
  },
  contactUsContainer: {
    margin: '30px 0 0 0'
  },
  contactUsButton: {
    height: '35px',
    width: '100px',
    padding: '10px 0px',
    borderRadius: '5px',
    '&:hover': {
      opacity: '70%'
    }
  },
  title: {
    marginBottom: '12px'
  },
  divider: {
    margin: '40px 0 12px'
  },
  logo: (isExtraSmall: boolean) => ({
    alignSelf: isExtraSmall ? 'flex-start' : 'flex-end'
  }),
  copyrightIcon: {
    fontSize: '1.2rem'
  },
  smallText: {
    fontFamily: 'noto_sansregular',
    fontSize: '1.2rem'
  }
}

export default styles
