/** @jsxImportSource @emotion/react */

import {Box, Divider, Skeleton, Stack, useMediaQuery, useTheme} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import MapSpain from '../../../assets/Map_Spain.svg'
import {type InsightCatalogueItemDto} from '../../../backendApi/models'
import {ETrackedEvents} from '../../../types'
import {trackEvent} from '../../../utils/mixpanel'
import {useOpenExplorer} from '../../../utils/useOpenExplorer'
import DemoCalendarDrawer from '../../demoCalendar/demoCalendar'
import QuarterBox from '../../reports/quarterBox/quarterBox'

import styles from './styles'

interface IReportDetailsSidebarProps {
  details?: InsightCatalogueItemDto
}

const ReportDetailsSidebar = ({details}: IReportDetailsSidebarProps): JSX.Element => {
  const {t} = useTranslation()
  const openExplorer = useOpenExplorer()
  const theme = useTheme()
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isNotSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const [createdOn, setCreatedOn] = useState<moment.Moment | undefined>()
  const [startDate, setStartDate] = useState<moment.Moment | undefined>()
  const [endDate, setEndDate] = useState<moment.Moment | undefined>()
  const [openDemoDrawer, setOpenDemoDrawer] = useState<boolean>(false)

  useEffect(() => {
    setCreatedOn(moment(details?.insightMetadata.createdOn))
    setStartDate(moment(details?.insightMetadata.dataTimeFrame?.startDate))
    setEndDate(moment(details?.insightMetadata.dataTimeFrame?.endDate))
  }, [details])

  return (
    <div>
      <Stack alignItems="center" spacing={1} sx={styles.container}>
        {details !== undefined ? (
          <Box>
            <p>
              <Button
                color="secondary"
                endIcon={<i className="_e277__ArrowRight_18px" />}
                sx={styles.button}
                onClick={() => {
                  if (details?.insightId !== undefined && details.insightMetadata.dashboardUrl !== undefined)
                    return (
                      trackEvent(ETrackedEvents.MarketExplorerClicked, {
                        'Sample Explorer': false,
                        'Market Explorer ID': details.insightId,
                        Title: details.insightMetadata.title
                      }),
                      openExplorer(details.insightMetadata.dashboardUrl)
                    )
                }}>
                {t('insightCatalogue:insightsDetail.accessDashboard.button')}
              </Button>
            </p>
            <Box style={{paddingTop: '15px', paddingBottom: '5px', textAlign: 'center'}}>
              <Typography variant="body2">{t('insightCatalogue:insightsDetail.bookDemo.title')}</Typography>
            </Box>
            <Button
              color="primary"
              sx={styles.button}
              onClick={() => {
                setOpenDemoDrawer(true)
                trackEvent(ETrackedEvents.ButtonClicked, {
                  Type: 'Book a demo'
                })
              }}>
              {t('insightCatalogue:insightsDetail.bookDemo.button')}
            </Button>
          </Box>
        ) : (
          <Skeleton height={50} variant="rectangular" width="90%" />
        )}
      </Stack>
      <Stack direction={{xs: 'column', sm: 'row', md: 'column'}} sx={styles.helpContainer}>
        {details !== undefined ? (
          <>
            <Stack>
              <Typography variant="h6">{t('insightCatalogue:insightsDetail.geographyCoveredTitle')}</Typography>

              <Typography sx={styles.descriptionText} variant="body2">
                {t('insightCatalogue:insightsDetail.geographyCoveredDescription')}
              </Typography>

              <Box sx={styles.imgContainer}>
                <Box component="img" src={MapSpain} sx={styles.mapImg} />
              </Box>
            </Stack>

            <Divider
              flexItem
              orientation={isExtraSmallScreen && isNotSmallScreen ? 'horizontal' : 'vertical'}
              sx={styles.divider}
            />
            <Stack justifyContent="space-between">
              {createdOn && startDate && endDate && (
                <Stack>
                  <QuarterBox quarter={`Q${Math.floor(createdOn.month() / 3 + 1)} ${createdOn.format('YY')}`} />
                  <Typography sx={[styles.text, styles.reportContains]}>
                    {t('insightCatalogue:insightsDetail.reportContains', {
                      range: `${Math.round(endDate.diff(startDate, 'months', true))}`,
                      quarter: `Q${Math.floor(createdOn.month() / 3 + 1)} ${createdOn.format('YYYY')}`
                    })}
                  </Typography>
                </Stack>
              )}

              <Stack direction="row" sx={styles.moreDetailsInfoContainer}>
                <Typography sx={[styles.text, styles.addedDate]}>
                  {t('insightCatalogue:insightsCards.added')}{' '}
                  {moment(details?.insightMetadata.createdOn).format('DD/MM/YYYY')}
                </Typography>
                <Typography sx={styles.text}>{details?.insightMetadata.pipelineVersion}</Typography>
              </Stack>
            </Stack>
          </>
        ) : (
          <Stack spacing={1}>
            <Skeleton
              sx={{
                fontSize: '2rem',
                width: {xs: '200px', sm: '350px', md: '75%'}
              }}
              variant="text"
            />
            <Skeleton
              height={60}
              sx={{
                width: {xs: '300px', sm: '450px', md: '100%'}
              }}
              variant="rectangular"
            />
          </Stack>
        )}
      </Stack>
      <DemoCalendarDrawer open={openDemoDrawer} onClose={() => setOpenDemoDrawer(false)} />
    </div>
  )
}

export default ReportDetailsSidebar
