/** @jsxImportSource @emotion/react */
import {Typography} from '@mui/material'
import React from 'react'

import {type EQuarter} from '../../../types'

import styles from './styles'

interface IQuarterBoxProps {
  quarter: string
}

const QuarterBox = ({quarter}: IQuarterBoxProps): JSX.Element => (
  <Typography sx={[styles.quarterBox, styles[quarter.split(' ')[0] as EQuarter]]}>{quarter}</Typography>
)

export default QuarterBox
