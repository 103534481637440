/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsightMetadataDto } from './InsightMetadataDto';
import {
    InsightMetadataDtoFromJSON,
    InsightMetadataDtoFromJSONTyped,
    InsightMetadataDtoToJSON,
} from './InsightMetadataDto';

/**
 * 
 * @export
 * @interface InsightCatalogueItemDto
 */
export interface InsightCatalogueItemDto {
    /**
     * 
     * @type {string}
     * @memberof InsightCatalogueItemDto
     */
    insightId: string;
    /**
     * 
     * @type {InsightMetadataDto}
     * @memberof InsightCatalogueItemDto
     */
    insightMetadata: InsightMetadataDto;
}

/**
 * Check if a given object implements the InsightCatalogueItemDto interface.
 */
export function instanceOfInsightCatalogueItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "insightId" in value;
    isInstance = isInstance && "insightMetadata" in value;

    return isInstance;
}

export function InsightCatalogueItemDtoFromJSON(json: any): InsightCatalogueItemDto {
    return InsightCatalogueItemDtoFromJSONTyped(json, false);
}

export function InsightCatalogueItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightCatalogueItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insightId': json['insightId'],
        'insightMetadata': InsightMetadataDtoFromJSON(json['insightMetadata']),
    };
}

export function InsightCatalogueItemDtoToJSON(value?: InsightCatalogueItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insightId': value.insightId,
        'insightMetadata': InsightMetadataDtoToJSON(value.insightMetadata),
    };
}

