/** @jsxImportSource @emotion/react */
import {Grid} from '@mui/material'
import React, {PropsWithChildren} from 'react'

export const CardListLoading = ({children}: PropsWithChildren<{}>) => (
  <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}} rowSpacing={2}>
    <Grid item>{children}</Grid>
    <Grid item>{children}</Grid>
  </Grid>
)
