import {type Theme} from '@mui/material'

interface IStyleOverrideProps {
  theme: Theme
}

export const MuiOutlinedInput = {
  styleOverrides: {
    root: ({theme}: IStyleOverrideProps) => ({
      borderRadius: '8px',
      backgroundColor: '#F6F5F7',
      '& .MuiOutlinedInput-input': {
        color: theme.palette.text.primary,
        '&:hover': {
          borderColor: '#878395'
        }
      }
    })
  }
}
