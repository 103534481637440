export default {
  highlightedText: {
    color: 'primary.main'
  },
  modelContainer: {
    display: 'grid',
    marginTop: '40px',
    paddingBottom: '80px'
  },
  sectitleText: {
    marginBottom: '6px',
    marginTop: '0'
  },
  smallText: {
    alignSelf: 'center',
    marginBottom: '0'
  }
}
