/** @jsxImportSource @emotion/react */
import {Unstable_Grid2 as Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, {useCallback, useState} from 'react'

import {InsightDescriptionSectionDto} from '../../../backendApi'
import CustomAccordion from '../../customMUI/customAccordion/customAccordion'
import MarkdownViewer from '../../markdownViewer/markdownViewer'

const styles = {
  accordionContainer: {
    display: {xs: 'none', lg: 'flex'},
    marginTop: '40px'
  }
}

export const MarketExplorerDescription = ({
  descriptionSections
}: {
  descriptionSections: InsightDescriptionSectionDto[]
}) => {
  const [expanded, setExpanded] = useState<number | false>(false)

  const handleChangeAccordion = useCallback(
    (index: number): void => {
      setExpanded(expanded === index ? false : index)
    },
    [expanded]
  )

  return (
    <Grid container sx={styles.accordionContainer}>
      {descriptionSections.map((section, index) => (
        <Grid
          key={section.title}
          sx={{
            width: '100%'
          }}>
          <CustomAccordion
            expanded={expanded === index}
            handleChange={() => handleChangeAccordion(index)}
            title={section.title}>
            <Typography component="div" variant="body2">
              <MarkdownViewer doc={section.content} />
            </Typography>
          </CustomAccordion>
        </Grid>
      ))}
    </Grid>
  )
}
