/** @jsxImportSource @emotion/react */
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import {Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {DataGrid} from './dataGrid/dataGrid'
import styles from './styles'

interface IExampleInsights {
  readonly title: string
  readonly url: string
}

interface IBrandsIncluded {
  readonly title: string
  readonly brands?: readonly string[]
}

export interface IMarketExplorerExampleProps {
  readonly title: string
  readonly brands?: readonly string[]
}

export const MarketExplorerExample = ({title, brands}: IMarketExplorerExampleProps): JSX.Element => {
  const {t} = useTranslation()

  const watchDemo = {
    title: t('insightCatalogue:insightsDetail.tabs.reports.watchDemo.title'),
    url: t('insightCatalogue:insightsDetail.tabs.reports.watchDemo.videoUrl')
  }

  const exampleInsights: IExampleInsights = {
    title: t('insightCatalogue:insightsDetail.tabs.reports.exampleinsights.title'),
    url: '/assets/images/IE-report.png'
  }

  const brandsIncluded: IBrandsIncluded = {
    title: t('insightCatalogue:insightsDetail.tabs.reports.brandsIncluded'),
    brands: brands
  }

  const [tab, setTab] = useState<string>(watchDemo.title)

  return (
    <>
      <Stack direction="row" justifyContent="space-between" style={styles.tableTitleContainer}>
        <Typography style={styles.tableTitle} variant="h6">
          {title}
        </Typography>
      </Stack>
      <TabContext value={tab}>
        <Box style={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs textColor="inherit" value={tab} onChange={(event, newTab: string) => setTab(newTab)}>
            <Tab label={watchDemo.title} style={styles.tabsTitle} value={watchDemo.title} />
            <Tab label={exampleInsights.title} style={styles.tabsTitle} value={exampleInsights.title} />
            {brandsIncluded.brands !== undefined && brandsIncluded.brands.length > 0 && (
              <Tab label={brandsIncluded.title} style={styles.tabsTitle} value={brandsIncluded.title} />
            )}
          </Tabs>
        </Box>
        <TabPanel style={styles.tabPanel} value={watchDemo.title}>
          <Box sx={styles.demoTab}>
            <iframe
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              height="100%"
              src={watchDemo.url}
              style={{...styles.demoIframe, position: 'absolute'}}
              title={watchDemo.title}
            />
          </Box>
        </TabPanel>
        <TabPanel style={styles.tabPanel} value={exampleInsights.title}>
          <img
            alt={t('insightCatalogue:insightsDetail.tabs.reports.exampleinsights.img')}
            css={styles.reportImg}
            src={exampleInsights.url}
          />
        </TabPanel>
        {brandsIncluded.brands !== undefined && brandsIncluded.brands.length > 0 && (
          <TabPanel style={styles.tabPanel} value={brandsIncluded.title}>
            <DataGrid data={brandsIncluded.brands} />
          </TabPanel>
        )}
      </TabContext>
    </>
  )
}
