export enum EQuarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export enum ECardType {
  CRMEnrichments = 'customer-data-enrichment',
  Reports = 'market-reports'
}

export type TInsightDetailsTableRows = Record<string, any>

export enum ETrackedEvents {
  ButtonClicked = 'Button Clicked',
  CardClicked = 'Card Clicked',
  ContactUs = 'Contact Us',
  CustomReportsInterest = 'Custom Reports Interest',
  DashboardViewed = 'Dashboard Viewed',
  FiltersApplied = 'Filters Applied',
  MarketExplorerClicked = 'Market Explorer Clicked',
  PageViewed = 'Page Viewed'
}
