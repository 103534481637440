/** @jsxImportSource @emotion/react */

import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import styles from './styles'

interface IErrorMessageProps {
  children: JSX.Element
  error: number
}

const ErrorMessage = ({children, error}: IErrorMessageProps): JSX.Element => {
  const {t} = useTranslation()
  return (
    <div css={styles.root}>
      <Typography color="primary" sx={styles.error}>
        {error}
      </Typography>
      <Typography variant="h5">
        {t('shared:errors.info.0')}
        <span css={styles.lightText}>{t('shared:errors.info.1')}</span>
      </Typography>
      <div css={styles.children}>{children}</div>
    </div>
  )
}

export default ErrorMessage
