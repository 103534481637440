/** @jsxImportSource @emotion/react */
import {Unstable_Grid2 as Grid} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import ContactUsDrawer from '../contactUsForm/contactUsDrawer'

import styles from './styles'

const InsightBanner = (): JSX.Element => {
  const {t} = useTranslation()
  const [openContact, setOpenContact] = useState<boolean>(false)

  return (
    <Grid
      container
      justifyContent={{sm: 'space-between', md: 'flex-start'}}
      rowSpacing={{xs: 2, sm: 0}}
      sx={styles.bannerContainer}>
      <Grid sm={6} sx={styles.leftContainer} xs={12}>
        <Typography sx={styles.leftText} variant="h1">
          {t('insightCatalogue:banner.privacyInsight')}
        </Typography>
      </Grid>
      <Grid sm={6} sx={styles.rightContainer} xs={12}>
        <Typography sx={styles.rightText} variant="h4">
          <ul css={styles.list}>
            <li css={styles.listElement}>{t('insightCatalogue:banner.description.0')}</li>
            <li css={styles.listElement}>{t('insightCatalogue:banner.description.1')}</li>
          </ul>
        </Typography>
        <Button
          endIcon={<i className="_e261__ArrowUp_9px" css={styles.contactIcon} />}
          variant="outlined"
          onClick={() => setOpenContact(true)}>
          {t('insightCatalogue:banner.contactUsButton')}
        </Button>
      </Grid>
      <ContactUsDrawer open={openContact} onClose={() => setOpenContact(false)} />
    </Grid>
  )
}

export default InsightBanner
