export const MuiButtonBase = {
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      pointerEvents: 'all' as const
    }
  }
}
