const styles = {
  body: {
    height: 'calc(var(--doc-height) - 95px - 80px)',
    padding: {
      xs: '24px 0 0',
      md: '24px 16px 0px',
      /**
       * The content width, as maximum, is 1152px. If we have the screen width capability, we need to make the rest of the width as padding,
       * If not we'll make just 16px in both sides (32px in total).
       * We need that since the large range goes from 1440px to 1024px and the width is within this range.
       * So to calculate the padding, we'll need to substract the width of the content to the size of the screen.
       * To know the width, we'll take the minimum between the max width (1152px) and the screen size substracted the padding value that we need (32px/2).
       * In that case, if we have a 1252px screen size we will have a padding of 0 50px.
       * And if we have a 1132px screen size we will have a padding of 0 16px.
       * */
      lg: '24px calc((100% - min(calc(100% - 32px), 1152px)) / 2) 0'
    },
    overflowY: 'scroll' as const
  },
  divider: {
    margin: {
      xs: '0 16px',
      lg: '0 calc((100% - min(calc(100% - 32px), 1152px)) / 2)'
    }
  },
  sidebar: {
    padding: {xs: '0px 16px', sm: '0px'},
    width: '100%'
  }
}

export default styles
