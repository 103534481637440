import mixpanel from 'mixpanel-browser'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {env} from '../env'
import {ETrackedEvents} from '../types'

type TProperties = Record<string, any>

let initialised = false

export const initialiseMixpanel = (): void => {
  try {
    // To test events in local, the api_host for the proxy server should be running.
    // Either comment it out or run the tracking-proxy container (https://github.com/mixpanel/tracking-proxy)
    mixpanel.init(env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      api_host: env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
      ignore_dnt: true,
      // Use local storage instead of Cookies to prevent a "Cookie Too Large" error
      persistence: 'localStorage'
      // debug: true,
    })

    initialised = true
  } catch (error) {
    // There is a problem with mixpanel but we want to keep the web site working
  }
}

export const trackEvent = (eventName: string, eventProps?: TProperties): void => {
  if (initialised) {
    try {
      mixpanel.track(eventName, eventProps)
    } catch (error) {
      // There is a problem with mixpanel but we want to keep the web site working
    }
  }
}

interface IIdentifyAttributes {
  sub: string
  given_name: string
  family_name: string
  email: string
}

export const identify = (attributes: IIdentifyAttributes): void => {
  try {
    // Link anonymous events to new user and create user profile in Mixpanel
    mixpanel.identify(attributes.sub)

    // Set user properties only if they don't exist
    mixpanel.people.set_once({
      $first_name: attributes.given_name,
      $last_name: attributes.family_name,
      $email: attributes.email,
      'User ID': attributes.sub,
      Plan: 'undefined'
    })
  } catch (error) {
    // There is a problem with mixpanel but we want to keep the web site working
  }
}

export const reset = (): void => {
  try {
    mixpanel.reset()
  } catch (error) {
    // There is a problem with mixpanel but we want to keep the web site working
  }
}

export const TrackPageViewed = (): JSX.Element | null => {
  const currentLocation = useLocation()

  useEffect(() => {
    const urlNoLang = window.location.pathname.replace(/^\/[^/]+/, '')

    if (initialised) {
      trackEvent(ETrackedEvents.PageViewed, {Page: urlNoLang})
    }
  }, [currentLocation])

  return null
}
