/** @jsxImportSource @emotion/react */
import {Link, type Theme, type SxProps} from '@mui/material'
import {type Variant} from '@mui/material/styles/createTypography'
import React from 'react'

interface ICustomLinkProps {
  color?: string
  classname?: SxProps<Theme>
  link: string
  text: string
  underline?: boolean
  variant?: Variant
}

const CustomLink = ({
  color = 'primary',
  classname,
  link,
  text,
  underline = false,
  variant
}: ICustomLinkProps): JSX.Element => (
  <Link
    color={color}
    href={link}
    rel="noreferrer noopener"
    sx={classname}
    target="_blank"
    underline={underline ? 'always' : 'none'}
    variant={variant}>
    {text}
  </Link>
)

export default CustomLink
