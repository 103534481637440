/** @jsxImportSource @emotion/react */
import {Button, Typography, Unstable_Grid2 as Grid, Box, Divider, Link} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {navigationInfo} from '../../config/urls'
import theme from '../../theme'
import {CustomDrawer} from '../customMUI/customDrawer/customDrawer'

import styles from './styles'

interface IMobileDrawerProps {
  open: boolean
  onClose: () => void
  openContactForm: () => void
}

const MobileMenuDrawer = ({open, onClose, openContactForm}: IMobileDrawerProps): JSX.Element => {
  const {t} = useTranslation()
  const {home} = navigationInfo
  const currentLocationUrl = useLocation().pathname

  return (
    <CustomDrawer isMobileMenu drawerAnchor="top" open={open} style={{zIndex: 2}} onClose={onClose}>
      <Box component={'div'}>
        <Box
          className="menu-stack"
          component={'div'}
          marginBottom={5}
          sx={styles.mobileMenuContainer}
          textAlign={'left'}>
          <Box component={'div'}>
            <Link
              color={`${currentLocationUrl === home.getUrl() ? theme.palette.primary.main : 'inherit'}`}
              underline="none"
              onClick={() => onClose()}>
              <Grid container alignItems={'center'}>
                <Grid marginY={3} xs={12}>
                  <Typography fontSize={'22px'}>Market Explorers</Typography>
                </Grid>
              </Grid>
            </Link>
            <Divider />
          </Box>
        </Box>
        <Box sx={styles.mobileButtonsContainer}>
          <Button sx={styles.bookDemoButton} variant="outlined" onClick={openContactForm}>
            {t('shared:header.bookDemo')}
          </Button>
        </Box>
      </Box>
    </CustomDrawer>
  )
}

export default MobileMenuDrawer
