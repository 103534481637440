/** @jsxImportSource @emotion/react */
import {Skeleton, Stack} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import {useTranslation} from 'react-i18next'

import styles from './styles'

interface IReportDetailsHeaderProps {
  text?: string
  onClose: () => void
}

const ReportDetailsHeader = ({text, onClose}: IReportDetailsHeaderProps): JSX.Element => {
  const {t} = useTranslation()

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={{xs: 'flex-start', lg: 'space-between'}}
      sx={styles.header}>
      <Typography sx={styles.title} variant="h2">
        {text !== undefined ? (
          <>
            {t('insightCatalogue:insightsDetail.marketReportTitle', {
              title: text
            })}
          </>
        ) : (
          <Skeleton sx={{width: {xs: '280px', sm: '500px'}}} />
        )}
      </Typography>
      <Button
        color="secondary"
        endIcon={<i className="_e217_Caret_Right_9px" css={styles.closeIcon} />}
        sx={styles.closeButton}
        variant="text"
        onClick={() => onClose()}>
        {t('shared:button.close')}
      </Button>
    </Stack>
  )
}

export default ReportDetailsHeader
