export default {
  root: (height: string) => ({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column' as const,
    fontSize: '1rem',
    height,
    justifyContent: 'center',
    lineHeight: '15px',
    textAlign: 'center' as const,
    textTransform: 'capitalize' as const,
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: 'text.primary'
    }
  }),
  rootSelected: {
    backgroundColor: '#FFFFFF',
    color: 'text.primary'
  },
  rootNotSelected: {
    color: 'white'
  },
  rootDisabled: {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  label: {
    fontSize: '1rem',
    margin: '2px',
    paddingTop: '6px',
    width: 'min-content'
  },
  icon: {
    fontSize: '2.4rem',
    paddingTop: '4px'
  }
}
