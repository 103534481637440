import Box from '@mui/material/Box'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SUPPORTED_LANGUAGES} from '../../config/constVariables'
import hubspotConfig from '../../config/hubspot'

import styles from './styles'

export const ContactUsForm = (): JSX.Element => {
  const {i18n} = useTranslation()

  const formId =
    i18n.language === SUPPORTED_LANGUAGES.EN.isoCode ? hubspotConfig.contactUsFormEn : hubspotConfig.contactUsFormEs

  return (
    <Box sx={styles.formContainer}>
      <iframe
        id={'contact-us-form-embed'}
        srcDoc={`
        <html>
          <body>
            <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
            <script>
              hbspt.forms.create({
                region: "${hubspotConfig.formRegion}",
                portalId: "${hubspotConfig.formPortalId}",
                formId: "${formId}"
              });
            </script>
          </body>
        </html>`}
        style={styles.iFrame}
        title="Contact us form"
      />
    </Box>
  )
}
