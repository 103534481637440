export default {
  footer: {
    alignItems: 'stretch',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column' as const,
    fontFamily: 'BlenderPro_medium',
    fontSize: '1rem',
    height: '31px',
    justifyContent: 'flex-start' as const,
    padding: '0px'
  },
  footerDetailStatus: {
    alignItems: 'flex-start' as const,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.2rem',
    margin: {xs: '6px 8px', sm: '6px 16px'}
  },
  footerText: {
    alignSelf: 'center',
    color: '#878395',
    fontFamily: 'noto_sansregular',
    fontSize: '1.2rem',
    marginBottom: '0',
    textTransform: 'uppercase' as const
  },
  tags: {
    border: '0.5px solid #878395',
    borderRadius: '20px',
    color: '#878395',
    height: '1.8rem',
    fontSize: '1.2rem',
    marginBottom: '0',
    marginLeft: '5px',
    padding: '0px 10px'
  }
}
