/** @jsxImportSource @emotion/react */
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Tooltip from '@mui/material/Tooltip'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {isTextClamped} from '../../../../utils/isEllipsisActive'
import {useOpenExplorer} from '../../../../utils/useOpenExplorer'

import styles from './styles'

interface IReportCardContentProps {
  description: string
  explorer?: string
}

const ReportCardContent = ({description, explorer}: IReportCardContentProps): JSX.Element => {
  const {t} = useTranslation()
  const openExplorer = useOpenExplorer()
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (descriptionRef.current !== null) {
      const isEllipsis = isTextClamped(descriptionRef.current, true)
      setTooltipEnabled(isEllipsis)
    }
  }, [descriptionRef])

  return (
    <CardContent sx={styles.cardContent}>
      <Tooltip arrow title={tooltipEnabled ? description : ''}>
        <p ref={descriptionRef} css={styles.description}>
          {description}
        </p>
      </Tooltip>

      {explorer !== undefined && (
        <Button
          color="secondary"
          endIcon={<i className="_e277__ArrowRight_18px" />}
          sx={styles.marketReportButton}
          onClick={(e) => {
            e.stopPropagation()
            openExplorer(explorer)
          }}>
          {t('insightCatalogue:insightsCards.status.button.marketReport')}
        </Button>
      )}
    </CardContent>
  )
}

export default ReportCardContent
