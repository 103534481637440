const typography = {
  fontFamily: '"noto_sansregular", serif',
  htmlFontSize: 10,
  h1: {
    fontFamily: '"BlenderPro_bold"',
    fontSize: '4rem',
    letterSpacing: '0.1em',
    lineHeight: '1',
    textTransform: 'uppercase' as const
  },
  h2: {
    fontFamily: '"BlenderPro_medium"',
    fontSize: '3rem',
    letterSpacing: '0.1em',
    lineHeight: '1',
    textTransform: 'uppercase' as const
  },
  h3: {
    fontFamily: '"BlenderPro_medium"',
    fontSize: '2.4rem',
    letterSpacing: '0.1em',
    lineHeight: '1.35',
    textTransform: 'uppercase' as const
  },
  h4: {
    fontFamily: '"BlenderPro_medium"',
    fontSize: '1.8rem',
    letterSpacing: '0.05em',
    lineHeight: '1.1',
    textTransform: 'uppercase' as const
  },
  h5: {
    fontFamily: '"noto_sansbold"',
    fontSize: '2.2rem',
    letterSpacing: '0',
    lineHeight: '1.35'
  },
  h6: {
    fontFamily: '"noto_sansbold"',
    fontSize: '1.8rem',
    letterSpacing: '0',
    lineHeight: '1.5'
  },
  subtitle1: {
    fontFamily: '"BlenderPro_bold"',
    fontSize: '1.8rem',
    letterSpacing: '0',
    lineHeight: '1.5',
    textTransform: 'uppercase' as const
  },
  subtitle2: {
    fontFamily: '"noto_sansbold"',
    fontSize: '1.4rem',
    letterSpacing: '0',
    lineHeight: '1.5'
  },
  body1: {
    fontFamily: '"noto_sansregular"',
    fontSize: '1.6rem',
    letterSpacing: '0',
    lineHeight: '1.5'
  },
  body2: {
    fontFamily: '"noto_sansregular"',
    fontSize: '1.4rem',
    letterSpacing: '0',
    lineHeight: '1.5'
  },
  button: {
    fontFamily: '"BlenderPro_medium"',
    fontSize: '1.4rem',
    letterSpacing: '0.1',
    lineHeight: '1.5',
    textTransform: 'uppercase' as const
  },
  caption: {
    fontFamily: '"noto_sansregular"',
    fontSize: '1rem',
    letterSpacing: '0',
    lineHeight: '1.5'
  },
  overline: {
    fontFamily: '"BlenderPro_medium"',
    fontSize: '1.2rem',
    letterSpacing: '0.05',
    lineHeight: '1.25',
    textTransform: 'uppercase' as const
  }
}

export default typography
