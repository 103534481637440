import {SUPPORTED_LANGUAGES} from './constVariables'
import i18n from './i18n'

type TNavigationPages = 'root' | 'home' | 'internalError' | 'insight'

interface IPageInfo {
  readonly url: string
  getUrl: (...args: string[]) => string
}

const buildUrl = (url = ''): string => `/${i18n.language ?? SUPPORTED_LANGUAGES.EN.isoCode}/${url}`

export const navigationInfo: Record<TNavigationPages, IPageInfo> = {
  root: {
    url: '/:lng',
    getUrl: () => buildUrl()
  },
  home: {
    url: '',
    getUrl: () => buildUrl()
  },
  internalError: {
    url: 'internalError',
    getUrl: () => buildUrl('internalError')
  },
  insight: {
    url: ':insightType/:insightId',
    getUrl: (insightType: string, insightId: string) => buildUrl(`${insightType}/${insightId}`)
  }
} as const
