import {ThemeProvider} from '@emotion/react'
import {ThemeProvider as MuiThemeProvider} from '@mui/material'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'

import './styles/index.css'
import App from './App'
import {persistor, store} from './app/store'
import configureApp from './config'
import FeatureFlagsProvider from './contexts/featureFlags'
import customTheme from './theme'
// https://momentjs.com/docs/#/i18n/loading-into-nodejs/
import 'moment/locale/es'

configureApp()

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={customTheme}>
            <ThemeProvider theme={customTheme}>
              <FeatureFlagsProvider>
                <App />
              </FeatureFlagsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
