/** @jsxImportSource @emotion/react */
import {Typography} from '@mui/material'
import React from 'react'

interface IOffice {
  country: string
  phone: string
}

interface IOfficeProps {
  office: IOffice
}

const Office = ({office}: IOfficeProps): JSX.Element => (
  <div>
    <Typography color="primary" variant="subtitle1">
      {office.country}
    </Typography>
    <Typography variant="subtitle1">{office.phone}</Typography>
  </div>
)

export default Office
