/** @jsxImportSource @emotion/react */
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {Accordion, AccordionDetails, AccordionSummary, Divider, Typography} from '@mui/material'
import React from 'react'

import styles from './styles'

interface ICustomAccordionProps {
  children: JSX.Element
  expanded: boolean
  handleChange: () => void
  title: string
}

const CustomAccordion = ({children, expanded, handleChange, title}: ICustomAccordionProps): JSX.Element => (
  <>
    <Accordion disableGutters expanded={expanded} sx={styles.accordionContainer(expanded)} onChange={handleChange}>
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />} sx={styles.accordionSummaryContainer}>
        <Typography color="primary" sx={styles.accordionTitle} variant="h5">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetailsContainer}>{children}</AccordionDetails>
    </Accordion>
    {!expanded && <Divider sx={styles.divider} />}
  </>
)

export default CustomAccordion
