export default {
  title: {
    fontFamily: 'BlenderPro_bold',
    marginBottom: '2px',
    textTransform: 'uppercase'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    '@media (max-width: 792px)': {
      margin: {
        xs: '31px max(calc((100% - 412px)/2), 8px) 15px',
        sm: '31px calc((100% - 412px)/2) 15px'
      }
    },
    '@media (max-width: 888px) and (min-width: 792px)': {
      margin: {
        sm: '31px 24px 15px'
      }
    },
    '@media (max-width: 1232px) and (min-width: 889px)': {
      margin: {
        md: '31px max(24px, calc((100% - 840px)/2)) 15px',
        lg: '31px 24px 15px'
      }
    },
    margin: {
      lg: '31px 1.6% 15px',
      xl: '31px 2.8% 15px'
    },
    paddingBottom: '20px',
    borderBottom: '1px solid #C3C1CA'
  },
  drawerBackdrop: {
    backgroundColor: 'rgba(15,7,42,0.9)',
    width: '100%'
  },
  drawerPaper: {
    height: 'calc(var(--doc-height) - 80px)',
    maxWidth: '1480px',
    overflow: 'initial',
    width: '100%'
  }
}
