import {type Theme} from '@mui/material'

interface IStyleOverrideProps {
  theme: Theme
}

export const MuiDrawer = {
  styleOverrides: {
    paper: () => ({
      height: '90vh',
      margin: 'auto',
      width: '70vw'
    }),
    paperAnchorBottom: ({theme}: IStyleOverrideProps) => ({
      [theme.breakpoints.down('md')]: {
        borderRadius: '16px 16px 0px 0px'
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '32px 32px 0px 0px'
      }
    }),
    paperAnchorTop: ({theme}: IStyleOverrideProps) => ({
      [theme.breakpoints.down('md')]: {
        borderRadius: '0px 0px 16px 16px'
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '0px 0px 32px 32px'
      }
    })
  }
}
