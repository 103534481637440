/** @jsxImportSource @emotion/react */
import {Box, Drawer, Grid, Typography} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import apiClient from '../../backendApi/api'
import {InsightCatalogueItemDto} from '../../backendApi/models'
import InsightBanner from '../../components/insightBanner/insightBanner'
import {ReportDetails} from '../../components/reportDetails/reportDetails'
import ReportCardList from '../../components/reports/reportCardList/reportCardList'
import {navigationInfo} from '../../config/urls'
import {InsightsRefreshProvider} from '../../contexts/insights'
import Main from '../../layout/main/main'

import styles from './styles'

const InsightCatalogueComponent = (): JSX.Element => {
  const {t, i18n} = useTranslation()

  const navigate = useNavigate()
  const {home} = navigationInfo
  const {insightId} = useParams()

  const {isPending, data} = useQuery<InsightCatalogueItemDto[], Error>({
    queryKey: ['catalogue', i18n.language],
    queryFn: async () => await apiClient.insightsApi.insightsFindAll()
  })

  const handleClose = useCallback(() => {
    navigate(home.getUrl())
  }, [home, navigate])

  return (
    <Main>
      <InsightBanner />
      <Box sx={styles.container}>
        <Grid container>
          <Grid item xs={12}>
            <div style={styles.titleContainer}>
              <Typography sx={styles.title} variant="h3">
                {t('marketExplorers:title')}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ReportCardList isLoading={isPending} itemList={data} />
          </Grid>
        </Grid>
      </Box>
      {insightId && (
        <Drawer
          anchor="bottom"
          open={insightId ? true : false}
          PaperProps={{sx: styles.drawerPaper}}
          slotProps={{
            backdrop: {sx: styles.drawerBackdrop}
          }}
          onClose={handleClose}>
          <ReportDetails insightId={insightId} onClose={handleClose} />
        </Drawer>
      )}
    </Main>
  )
}

// This is just a wrapper so we can use the InsightsRefreshProvider inside the InsightCatalogue page component
export const InsightCatalogue = (): JSX.Element => (
  <InsightsRefreshProvider>
    <InsightCatalogueComponent />
  </InsightsRefreshProvider>
)
