export default {
  header: {
    backgroundColor: 'white',
    borderRadius: '32px 32px 0px 0px',
    height: {xs: 'fit-content', lg: '94px'},
    margin: {
      /**
       * The content width, as maximum, is 1152px. If we have the screen width capability, we need to make the rest of the width as padding,
       * If not we'll make just 16px in both sides (32px in total).
       * We need that since the large range goes from 1440px to 1024px and the width is within this range.
       * So to calculate the padding, we'll need to substract the width of the content to the size of the screen.
       * To know the width, we'll take the minimum between the max width (1152px) and the screen size substracted the padding value that we need (32px/2).
       * In that case, if we have a 1252px screen size we will have a padding of 0 50px.
       * And if we have a 1132px screen size we will have a padding of 0 16px.
       * */
      lg: '0 calc((100% - min(calc(100% - 32px), 1152px)) / 2)'
    },
    padding: {
      xs: '16px',
      lg: '0px'
    }
  },
  title: {
    marginBottom: '0px'
  },
  closeButton: {
    color: {xs: 'white', lg: 'inherit'},
    fontSize: '1.8rem',
    position: {xs: 'absolute', lg: 'inherit'},
    right: {xs: '26px', lg: 'inherit'},
    top: {xs: '-35px', lg: 'inherit'}
  },
  closeIcon: {
    fontSize: '1.4rem!important',
    transform: 'rotate(90deg)'
  }
}
