export const styles = {
  moreInfoContainer: {
    margin: '12px 0 30px'
  },
  text: {
    alignSelf: 'flex-start',
    color: '#878395',
    fontSize: '1.3rem',
    marginBottom: '0'
  },
  textUppercase: {
    textTransform: 'uppercase' as const
  },
  textBold: {
    fontFamily: 'noto_sansbold'
  },
  tags: {
    border: '1px solid #878395',
    borderRadius: '20px',
    color: '#878395',
    marginBottom: '0',
    marginRight: '5px',
    padding: '1px 10px'
  }
}
