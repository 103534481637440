import React, {createContext, useState} from 'react'

import {env} from '../env'

export interface IFeatureFlagsContext {
  isMoreInfoCardEnabled: boolean
  isSDKEnabled: boolean
  isSuitableSectionEnabled: boolean
  isHubspotCalendarEnabled: boolean
}

export const FeatureFlags = createContext<IFeatureFlagsContext>({
  isMoreInfoCardEnabled: true,
  isSDKEnabled: true,
  isSuitableSectionEnabled: true,
  isHubspotCalendarEnabled: true
})

interface IFeatureFlagsProviderProps {
  children?: React.ReactNode
}

const FeatureFlagsProvider = ({children}: IFeatureFlagsProviderProps): JSX.Element => {
  // we need to call .toString() when comparing with the env vars because they have type 'object' and
  // the check would return false if we don't do it (even if the value is 'true')
  const [features] = useState<IFeatureFlagsContext>({
    isMoreInfoCardEnabled: false,
    isSDKEnabled: false,
    isSuitableSectionEnabled: false,
    isHubspotCalendarEnabled: env.REACT_APP_IS_HUBSPOT_CALENDAR_ENABLED?.toString() === 'true'
  })

  return <FeatureFlags.Provider value={features}>{children}</FeatureFlags.Provider>
}

export default FeatureFlagsProvider
