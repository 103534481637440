export default {
  root: {
    padding: {xs: '16px', md: '0 40px 0 0'}
  },
  tableContainer: {
    alignItems: 'center',
    borderRadius: '16px',
    border: '1px solid #c3c1ca',
    marginTop: '20px'
  }
}
