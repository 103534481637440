import {createTheme, type ThemeOptions} from '@mui/material'

import breakpoints from './breakpoints'
import * as components from './components'
import palette from './palette'
import typography from './typography'

// @see Theme customization here: https://mui.com/customization/theming/
// @see Default theme properties here: https://mui.com/customization/default-theme/
const themeOptions: ThemeOptions = {
  breakpoints,
  components,
  palette,
  typography
}

const theme = createTheme(themeOptions)

export default theme
