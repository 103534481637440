import {type Theme} from '@mui/material'

interface IRootOverrideProps {
  theme: Theme
}

export const MuiBreadcrumbs = {
  styleOverrides: {
    root: ({theme}: IRootOverrideProps) => ({
      fontFamily: '"BlenderPro_medium"',
      fontSize: '1.3rem',
      letterSpacing: '0.1em',
      textTransform: 'uppercase' as const,
      'li:last-of-type': {
        color: theme.palette.primary.dark,
        fontWeight: 700
      }
    })
  }
}
