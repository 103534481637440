export default {
  button: {
    width: '82px'
  },
  popoverContainer: (marginTop: string) => ({
    '.MuiPopover-paper': {
      marginTop
    },
    borderRadius: '8px'
  })
}
