import {Button, Popover, type PopoverOrigin} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import LanguagePopover from './languagePopover/languagePopover'
import styles from './styles'

interface ILanguageMenuProps {
  anchorOrigin: PopoverOrigin
  color: 'primary' | 'secondary'
  margin: string
  transformOrigin: PopoverOrigin
  variant: 'text' | 'outlined'
}

const LanguageMenu = ({anchorOrigin, color, margin, transformOrigin, variant}: ILanguageMenuProps): JSX.Element => {
  const {i18n} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  return (
    <>
      <Button
        color={color}
        startIcon={<i className="_e905_Language_20px" />}
        sx={styles.button}
        variant={variant}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        {i18n.language}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        open={Boolean(anchorEl)}
        sx={styles.popoverContainer(margin)}
        transformOrigin={transformOrigin}
        onClose={() => setAnchorEl(null)}>
        <LanguagePopover selected={i18n.language} onSelect={() => setAnchorEl(null)} />
      </Popover>
    </>
  )
}

export default LanguageMenu
