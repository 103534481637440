import {useNavigate} from 'react-router-dom'

import {navigationInfo} from '../config/urls'

export const useOpenExplorer = () => {
  const navigate = useNavigate()
  const {internalError} = navigationInfo

  const openExplorer = (explorer: string) => {
    try {
      window.open(explorer, '_blank')
    } catch (error) {
      navigate(internalError.getUrl())
    }
  }
  return openExplorer
}
