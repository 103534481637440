export default {
  root: {
    backgroundColor: '#3B4E60',
    borderRadius: '0 30px 30px 0',
    display: {xs: 'none', lg: 'initial'},
    height: 'var(--doc-height)',
    minWidth: '80px',
    maxWidth: '80px',
    position: 'sticky' as const,
    top: 0
  },
  gap: {
    height: '80px'
  }
}
