/** @jsxImportSource @emotion/react */
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {navigationInfo} from '../../config/urls'

import NavbarItem from './navbarItem/navbarItem'
import styles from './styles'

interface INavbarProps {
  showBackButton?: boolean
}

const Navbar = ({showBackButton = false}: INavbarProps): JSX.Element => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const {home} = navigationInfo

  const items = [
    {
      iconClass: '_e220_Dashboard_20px',
      enabled: true,
      label: 'Market Explorers',
      path: home.getUrl()
    }
  ]

  return (
    <Box component="nav" sx={styles.root}>
      {showBackButton ? (
        <NavbarItem
          key="back-button"
          height="80px"
          item={{
            iconClass: '_e276__ArrowLeft_18px',
            label: t('shared:navbar.back')
          }}
          selected={false}
          onClick={() => navigate(-1)}
        />
      ) : (
        <div css={styles.gap} />
      )}
      {items
        .filter((item) => item.enabled)
        .map((item, index) => (
          <NavbarItem
            key={index}
            height="90px"
            item={item}
            selected={location.pathname === item.path}
            onClick={() => navigate(item.path)}
          />
        ))}
    </Box>
  )
}

export default Navbar
