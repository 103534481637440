/** @jsxImportSource @emotion/react */
import {useTheme, useMediaQuery, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SUPPORTED_LANGUAGES} from '../../config/constVariables'
import hubspotConfig from '../../config/hubspot'
import i18n from '../../config/i18n'
import {CustomDrawer} from '../customMUI/customDrawer/customDrawer'

import styles from './styles'

interface IDemoCalendarProps {
  open: boolean
  onClose: () => void
}

const DemoCalendarDrawer = ({open, onClose}: IDemoCalendarProps): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const embedUrl =
    i18n.language === SUPPORTED_LANGUAGES.EN.isoCode
      ? hubspotConfig.embedCalendarUrlEn
      : hubspotConfig.embedCalendarUrlEs

  return (
    <CustomDrawer
      isMobileForm
      drawerAnchor={`${isMobile ? 'top' : 'bottom'}`}
      open={open}
      title={t('insightCatalogue:demoCalendar.title')}
      onClose={() => onClose()}>
      <div>
        <Typography variant="body2">{t('insightCatalogue:demoCalendar.info1')}</Typography>
        <Typography marginBottom={2} variant="body2">
          {t('insightCatalogue:demoCalendar.info2')}
        </Typography>
        <iframe src={embedUrl} style={styles.calendarStyles} title={'hubspot calendar embed'} />
      </div>
    </CustomDrawer>
  )
}

export default DemoCalendarDrawer
