export default {
  description: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical' as const,
    overflow: 'hidden'
  },
  cardContent: {
    color: '#2e2746',
    fontSize: '1.3rem',
    height: '153px',
    padding: {xs: '0px 8px 4px', sm: '0px 16px 4px'},
    overflow: 'hidden'
  },
  marketReportButton: {
    fontSize: '1.4rem',
    height: '26px'
  }
}
