/** @jsxImportSource @emotion/react */
import {Box, Drawer, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import {useTranslation} from 'react-i18next'

import styles from './styles'

interface ICustomDrawerProps {
  children: JSX.Element
  onClose: () => void
  open: boolean
  title?: string
  drawerAnchor?: 'top' | 'right' | 'bottom' | 'left'
  isMobileMenu?: boolean
  isMobileForm?: boolean
  style?: Record<string, unknown>
}

export const CustomDrawer = ({
  children,
  onClose,
  open,
  title,
  drawerAnchor = 'bottom',
  isMobileMenu = false,
  isMobileForm = false,
  style
}: ICustomDrawerProps): JSX.Element => {
  const {t} = useTranslation()
  return (
    <Drawer
      anchor={drawerAnchor}
      BackdropProps={{sx: styles.drawerBackdrop}}
      open={open}
      PaperProps={{
        sx: !isMobileMenu ? styles.drawerPaper : styles.drawerMobileMenuPaper
      }}
      style={style}
      onClose={onClose}>
      <Box sx={styles.drawerContainer}>
        <div css={styles.header}>
          {!isMobileMenu && (
            <Button
              color="secondary"
              endIcon={<i className="_e217_Caret_Right_9px" css={styles.closeIcon} />}
              sx={styles.closeButton}
              variant="text"
              onClick={onClose}>
              {t('shared:button.close')}
            </Button>
          )}
          {isMobileForm && (
            <Button
              color="secondary"
              endIcon={<i className="_e217_Caret_Right_9px" css={styles.closeIcon} />}
              sx={styles.closeFormButton}
              variant="text"
              onClick={onClose}
            />
          )}
        </div>
        <div>
          <Typography sx={styles.title} variant="h2">
            {title}
          </Typography>
          {children}
        </div>
      </Box>
    </Drawer>
  )
}
