export default {
  root: {
    background: '#f6f5f7',
    display: 'flex',
    overflowX: 'clip' as const,
    width: '100%',
    minHeight: '100vh'
  },
  body: {
    width: {
      xs: '100%',
      lg: 'calc(100% - 80px)' // 80px is the width of the sidebar
    }
  },
  childrenContainer: {
    flex: 1
  }
}
