import {EQuarter} from '../../../types'

export default {
  quarterBox: {
    alignSelf: 'flex-start',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'BlenderPro_medium',
    fontSize: '1.2rem',
    height: '1.7rem',
    marginBottom: '0',
    marginRight: '11px',
    padding: '0 4px',
    textTransform: 'uppercase',
    width: 'fit-content'
  },
  [EQuarter.Q1]: {background: '#5123E3'},
  [EQuarter.Q2]: {background: '#FFBE0B', color: '#0F072A'},
  [EQuarter.Q3]: {background: '#239FE3'},
  [EQuarter.Q4]: {background: '#59CD90'}
}
