import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'

import {type InsightCatalogueItemDto} from '../../../backendApi/models'
import {CardListLoading} from '../../cardListLoading/cardListLoading'
import ReportCard from '../reportCard/reportCard'
import ReportCardSkeleton from '../reportCardSkeleton/reportCardSkeleton'

interface IReportCardListProps {
  isLoading: boolean
  itemList?: InsightCatalogueItemDto[]
}

const ReportCardList = ({isLoading, itemList}: IReportCardListProps): JSX.Element => {
  if (isLoading) {
    return (
      <CardListLoading>
        <ReportCardSkeleton />
      </CardListLoading>
    )
  }

  return (
    <Grid container justifyContent={{xs: 'center', lg: 'flex-start'}} spacing={2}>
      {itemList?.map((item: InsightCatalogueItemDto) => (
        <Grid key={item.insightId}>
          <ReportCard item={item} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ReportCardList
