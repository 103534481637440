export const styles = {
  headerSkeleton: {
    height: '24px',
    marginBottom: '22px',
    width: {xs: '175px', sm: '241px', md: '216px', lg: '241px'}
  },
  cardFooterSkeleton: {
    height: '18px',
    marginTop: '29px',
    width: {xs: '65px', sm: '90px', md: '80px', lg: '90px'}
  },
  buttonSkeleton: {
    borderRadius: '13px',
    height: '26px',
    marginTop: '20px',
    width: {xs: '117px', sm: '160px', md: '144px', lg: '160px'}
  },
  cardContainer: {
    position: 'relative' as const,
    paddingTop: '20px'
  },
  card: {
    borderRadius: '16px',
    height: '243px',
    width: {xs: '300px', sm: '412px', md: '369px', lg: '412px'},
    position: 'relative' as const,
    zIndex: 2
  },
  cardContentSkeleton: {
    height: '36px',
    marginBottom: '12px',
    width: {xs: '240px', sm: '329px', md: '295px', lg: '329px'}
  },
  skeletonContent: {
    marginLeft: '14px',
    marginTop: '10px'
  }
}
