/** @jsxImportSource @emotion/react */
import {Card, Skeleton} from '@mui/material'
import React from 'react'

import {styles} from './styles'

const ReportCardSkeleton = (): JSX.Element => (
  <div css={styles.cardContainer}>
    <Card sx={styles.card} variant="outlined">
      <div css={styles.skeletonContent}>
        <Skeleton sx={styles.headerSkeleton} variant="rectangular" />
        <Skeleton sx={styles.cardContentSkeleton} variant="rectangular" />
        <Skeleton sx={styles.cardContentSkeleton} variant="rectangular" />
        <Skeleton sx={styles.buttonSkeleton} variant="rounded" />
      </div>
    </Card>
  </div>
)

export default ReportCardSkeleton
