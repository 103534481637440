import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'

import LanguageRouter from './components/languageRouter/languageRouter'
import {navigationInfo} from './config/urls'
import InternalError from './pages/Errors/InternalError/internalError'
import NotFound from './pages/Errors/NotFound/notFound'
import {InsightCatalogue} from './pages/InsightCatalogue/insightCatalogue'
import {TrackPageViewed} from './utils/mixpanel'

const App = (): JSX.Element => {
  const {root, home, internalError, insight} = navigationInfo

  const errorHandler = (): void => {
    // TODO: redirect error to k8s log
    // console.error(error, info.componentStack);
    window.location.replace(internalError.getUrl())
  }

  return (
    <Router>
      <ErrorBoundary FallbackComponent={() => <></>} onError={errorHandler}>
        <Routes>
          <Route element={<Navigate replace to={home.getUrl()} />} path="/" />
          <Route Component={LanguageRouter} path={root.url}>
            <Route Component={InsightCatalogue} path={home.url} />
            <Route Component={InsightCatalogue} path={insight.url} />
            <Route Component={InternalError} path={internalError.url} />
            <Route Component={NotFound} path="*" />
          </Route>
        </Routes>
        <TrackPageViewed />
      </ErrorBoundary>
    </Router>
  )
}

export default App
