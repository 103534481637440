/** @jsxImportSource @emotion/react */
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import ErrorMessage from '../../../components/errorMessage/errorMessage'
import {navigationInfo} from '../../../config/urls'
import Main from '../../../layout/main/main'

import styles from './styles'

const NotFound = (): JSX.Element => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {home} = navigationInfo

  return (
    <Main showFooter={false}>
      <div css={styles.errorContainer}>
        <ErrorMessage error={404}>
          <Button
            endIcon={<i className="_e104_ArrowUp_20px" css={styles.arrowIcon} />}
            onClick={() => navigate(home.getUrl())}>
            {t('shared:errors.toStore')}
          </Button>
        </ErrorMessage>
      </div>
    </Main>
  )
}

export default NotFound
