export const MuiStepIcon = {
  styleOverrides: {
    root: {
      color: '#E2E1E6',
      text: {
        fill: 'black'
      },
      '&.Mui-active': {
        color: '#59CD90',
        text: {
          fill: 'white'
        }
      }
    }
  }
}
