export default {
  container: {
    marginBottom: '22px'
  },
  button: {
    alignSelf: 'center',
    fontSize: '2.2rem',
    height: '50px',
    maxWidth: '330px',
    width: '100%'
  },
  helpContainer: {
    backgroundColor: '#F1F0F2',
    borderRadius: '16px',
    margin: {xs: '0px', sm: '0 16px', md: '0px'},
    maxWidth: {xs: 'none', md: '370px'},
    padding: '22px'
  },
  moreDetailsInfoContainer: {
    color: '#878395',
    display: 'flex',
    justfyContent: 'space-between',
    marginTop: '10px'
  },
  addedDate: {
    marginRight: '10px',
    textTransform: 'uppercase'
  },
  descriptionText: {
    margin: '9px 0 13px'
  },
  divider: {
    backgroundColor: '#C3C1CA',
    borderBottomWidth: '0.01px',
    margin: {xs: '0px 0px 32px', sm: '0px 20px 0px 22px', md: '0px 0px 32px'}
  },
  imgContainer: {
    height: {xs: '178px', sm: '142px', md: 'auto'},
    overflow: 'hidden'
  },
  mapImg: {
    '@media (max-width: 400px) and (min-width: 320px)': {
      transformOrigin: {xs: '0% 113%'},
      transform: {xs: 'scale(2.4)'}
    },
    '@media (max-width: 450px) and (min-width: 401px)': {
      transformOrigin: {xs: '0% 120%'},
      transform: {xs: 'scale(2.4)'}
    },
    transform: {xs: 'scale(2)', sm: 'scale(2)', md: 'scale(2.9)'},
    transformOrigin: {xs: '0% 144%', sm: '0% 136%', md: '0% 100%'},
    width: '100%'
  },
  text: {
    alignSelf: 'center',
    color: '#878395',
    fontSize: '1.3rem',
    marginBottom: '0'
  },
  reportContains: {
    marginTop: '5px'
  }
}
