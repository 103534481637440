import {SvgIconProps, SvgIcon} from '@mui/material'
import React from 'react'

import theme from '../theme'

export const TwitterIcon = (props: Omit<SvgIconProps, 'viewBox'>) => {
  return (
    <SvgIcon
      height="32"
      viewBox="0 0 33 32"
      width="33"
      {...props}
      sx={{
        fontSize: 32,
        fill: theme.palette.primary.dark,
        opacity: '90%',
        '&:hover': {
          opacity: '70%'
        }
      }}>
      <rect height="31" rx="15.5" width="32" />
      <path
        d="M22.5012 12.9715C22.511 13.1137 22.511 13.2559 22.511 13.3994C22.511 17.7724 19.1884 22.8157 13.1128 22.8157V22.8131C11.3181 22.8157 9.56067 22.3006 8.0498 21.3295C8.31077 21.3609 8.57305 21.3766 8.83598 21.3773C10.3233 21.3786 11.7681 20.8786 12.9382 19.9579C11.5248 19.931 10.2854 19.0077 9.85238 17.6597C10.3475 17.7553 10.8577 17.7357 11.3436 17.6027C9.80267 17.2907 8.69405 15.9342 8.69405 14.3588C8.69405 14.3444 8.69405 14.3306 8.69405 14.3169C9.1532 14.5731 9.66728 14.7153 10.1931 14.731C8.7418 13.7592 8.29442 11.8247 9.17086 10.3122C10.8479 12.3798 13.3221 13.6367 15.9783 13.7697C15.7121 12.6203 16.0757 11.4158 16.9338 10.6078C18.2642 9.3548 20.3565 9.41902 21.6071 10.7513C22.3468 10.6051 23.0558 10.3332 23.7046 9.94786C23.458 10.7139 22.942 11.3647 22.2526 11.7782C22.9073 11.7008 23.547 11.5252 24.1494 11.2572C23.7059 11.923 23.1474 12.503 22.5012 12.9715Z"
        fill="white"
      />
    </SvgIcon>
  )
}
