/** @jsxImportSource @emotion/react */
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {CardHeader, Card, IconButton, Divider, Tooltip, Typography, CardMedia} from '@mui/material'
import moment from 'moment'
import React, {useContext, useEffect, useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'

import {type InsightCatalogueItemDto} from '../../../backendApi/models'
import {navigationInfo} from '../../../config/urls'
import {FeatureFlags, type IFeatureFlagsContext} from '../../../contexts/featureFlags'
import {ECardType, ETrackedEvents, type EQuarter} from '../../../types'
import {isTextClamped} from '../../../utils/isEllipsisActive'
import {trackEvent} from '../../../utils/mixpanel'

import ReportCardActions from './reportCardActions/reportCardActions'
import ReportCardContent from './reportCardContent/reportCardContent'
import styles from './styles'

export interface IReportCardProps {
  item: InsightCatalogueItemDto
}
const ReportCard = ({item}: IReportCardProps): JSX.Element => {
  const navigate = useNavigate()
  const features = useContext<IFeatureFlagsContext>(FeatureFlags)
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false)
  const titleRef = useRef(null)

  const {insight} = navigationInfo

  useEffect(() => {
    if (titleRef.current !== null) {
      const isEllipsis = isTextClamped(titleRef.current)
      setTooltipEnabled(isEllipsis)
    }
  }, [titleRef])

  return (
    <Card
      sx={styles.card}
      variant="outlined"
      onClick={() => {
        trackEvent(ETrackedEvents.CardClicked, {
          'Card Type': 'Market explorer',
          'Market Explorer ID': item.insightId,
          Title: item.insightMetadata.title
        })
        navigate(insight.getUrl(ECardType.Reports, item.insightId))
      }}>
      <CardMedia
        alt={item.insightMetadata.title}
        component="img"
        src={`/assets/images/insights/${item.insightId}.webp`}
        sx={styles.cardMedia}
      />
      <div>
        <CardHeader
          action={
            features.isMoreInfoCardEnabled && (
              <IconButton aria-label="settings" sx={styles.moreButton}>
                <MoreVertIcon />
              </IconButton>
            )
          }
          disableTypography={false}
          sx={styles.header}
          title={
            <Tooltip arrow placement="bottom" title={tooltipEnabled ? item.insightMetadata.title : ''}>
              <Typography ref={titleRef} sx={styles.title} variant="h4">
                {item.insightMetadata.title}
              </Typography>
            </Tooltip>
          }
        />
        <Divider
          sx={[
            styles.divider,
            styles.dividerColor[
              `Q${Math.floor(moment(item.insightMetadata.createdOn, 'YYYYMMDD').month() / 3 + 1)}` as EQuarter
            ]
          ]}
          variant="middle"
        />

        {item.insightMetadata.description !== undefined && (
          <ReportCardContent
            description={item.insightMetadata.description}
            explorer={item.insightMetadata.dashboardUrl}
          />
        )}

        <Divider sx={styles.divider} variant="middle" />
        {item.insightMetadata.createdOn !== undefined && item.insightMetadata.industry !== undefined && (
          <ReportCardActions createdOn={item.insightMetadata.createdOn} industries={item.insightMetadata.industry} />
        )}
      </div>
    </Card>
  )
}

export default ReportCard
