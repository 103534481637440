const styles = {
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(var(--doc-height) - 80px)'
  },
  arrowIcon: {
    transform: 'rotate(90deg)'
  }
}

export default styles
