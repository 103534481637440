export const isTextClamped = (element: any, multiline = false): boolean => {
  if (element !== null) {
    if (multiline) {
      return element.scrollHeight > element.clientHeight
    } else {
      return element.offsetWidth < element.scrollWidth
    }
  }

  return false
}
