/** @jsxImportSource @emotion/react */
import {Box, CardActions, Typography, useMediaQuery, useTheme} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import QuarterBox from '../../quarterBox/quarterBox'

import styles from './styles'

interface IReportCardActionsProps {
  createdOn: string
  industries: string[]
}

const ReportCardActions = ({createdOn, industries}: IReportCardActionsProps): JSX.Element => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <CardActions css={styles.footer} disableSpacing={true}>
      <Box sx={styles.footerDetailStatus}>
        <QuarterBox quarter={`Q${Math.floor(moment(createdOn).month() / 3 + 1)} ${moment(createdOn).format('YY')}`} />
        <Typography sx={styles.footerText} variant="subtitle2">
          {isSmall ? t('insightCatalogue:insightsCards.data') : t('insightCatalogue:insightsCards.dataFrom')}:
        </Typography>
        {industries.map((industry: string) => (
          <Typography key={industry} css={styles.tags} variant="body2">
            {industry}
          </Typography>
        ))}
      </Box>
    </CardActions>
  )
}

export default ReportCardActions
