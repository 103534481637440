import {env} from '../env'

const hubspotConfig = {
  embedCalendarUrlEn: env.REACT_APP_HUBSPOT_EMBED_CALENDAR_URL_EN,
  embedCalendarUrlEs: env.REACT_APP_HUBSPOT_EMBED_CALENDAR_URL_ES,
  contactUsFormEn: env.REACT_APP_HUBSPOT_CONTACT_US_FORM_ID_EN,
  contactUsFormEs: env.REACT_APP_HUBSPOT_CONTACT_US_FORM_ID_ES,
  formRegion: 'na1',
  formPortalId: '4095133'
}

export default hubspotConfig
