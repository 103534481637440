/** @jsxImportSource @emotion/react */
import {Box} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {type InsightCatalogueItemDto} from '../../../backendApi/models'
import {useOpenExplorer} from '../../../utils/useOpenExplorer'
import {ModelTraining} from '../../modelTraining/modelTraining'
import {MarketExplorerExample} from '../../reports/marketExplorerExample/marketExplorerExample'

import {MarketExplorerDescription} from './marketExplorerDescription'
import {ReportDescription} from './reportDescription/reportDescription'
import styles from './styles'

interface IReportDetailsExtendedProps {
  details: InsightCatalogueItemDto
}

export const ReportDetailsInfo = ({details}: IReportDetailsExtendedProps) => {
  const {t} = useTranslation()

  const openExplorer = useOpenExplorer()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  useEffect(() => {
    if (openDrawer) {
      setOpenDrawer(false)
      if (details.insightMetadata.dashboardUrl) {
        openExplorer(details.insightMetadata.dashboardUrl)
      }
    }
  }, [details.insightMetadata.dashboardUrl, openExplorer, openDrawer])

  return (
    <Box sx={styles.root}>
      <ReportDescription insight={details.insightMetadata} />
      <Box sx={styles.tableContainer}>
        <MarketExplorerExample
          brands={details.insightMetadata.brands?.map((brand) => brand.label)}
          title={t('insightCatalogue:insightsDetail.tableReportTitle.reports')}
        />
      </Box>
      <MarketExplorerDescription descriptionSections={details.insightMetadata.descriptionSections} />
      {details.insightMetadata.coverage !== undefined &&
        details.insightMetadata.numberOfEnrichments !== undefined &&
        details.insightMetadata.numberOfUniqueCRMRecordsTrained !== undefined && (
          <ModelTraining
            coverage={details.insightMetadata.coverage}
            endDate={details.insightMetadata.dataTimeFrame?.endDate}
            numberOfEnrichments={details.insightMetadata.numberOfEnrichments}
            numberOfUniqueCRMRecordsTrained={details.insightMetadata.numberOfUniqueCRMRecordsTrained}
            startDate={details.insightMetadata.dataTimeFrame?.startDate}
          />
        )}
    </Box>
  )
}
