import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React from 'react'

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 10,
  padding: '10px',
  width: '10px',
  overflow: 'clip'
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

// This component takes a list of data and transforms it into a matrix to display it as a grid
export const DataGrid = ({data}: {data: readonly string[]}) => {
  const dataToDisplay = () => {
    const numOfColumns = 5
    const numOfRows = Math.ceil(data.length / numOfColumns)

    const padRight = (arr: string[], padWith: string, newLength: number) =>
      arr.concat(new Array(newLength).fill(padWith))

    const dataMatrix = new Array(numOfRows).fill('').map((e, index) => {
      const startFrom = index * numOfColumns
      const row = data.slice(startFrom, startFrom + numOfColumns)

      // We want to add empty cells if the data doesn't fill the full row
      // so that we get a consistent style in each one of them
      return row.length === numOfColumns ? row : padRight(row, '', numOfColumns - row.length)
    })

    return dataMatrix
  }

  return (
    <Table>
      <TableBody>
        {dataToDisplay().map((row, rowIndex) => (
          <StyledTableRow key={rowIndex}>
            {row.map((brand, brandIndex) => (
              <StyledTableCell key={brandIndex} align="left">
                {brand}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}
