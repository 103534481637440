import {EQuarter} from '../../../types'

export default {
  title: {
    color: '#2e2746',
    fontSize: '1.8rem',
    marginBottom: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
    '@media (max-width: 791px)': {
      width: {
        xs: 'min(calc(100vw - 77px), 349px)',
        sm: '333px'
      }
    },
    '@media (max-width: 888px) and (min-width: 792px)': {
      width: {
        sm: 'min(calc((100vw - 222px) / 2), 333px)'
      }
    },
    '@media (max-width: 1232px) and (min-width: 889px)': {
      width: {
        md: '333px'
      }
    },
    width: {
      lg: '292px',
      xl: '306px'
    }
  },
  card: {
    borderRadius: '16px',
    border: '1px solid #C3C1CA',
    cursor: 'pointer',
    display: 'flex',
    height: '230px',
    maxWidth: '410px',
    minWidth: '300px',
    position: 'relative' as const,
    zIndex: 2,
    '&:hover': {
      border: '1px solid #5123E3'
    },
    '&:hover > img': {
      filter: 'brightness(60%)'
    },
    '@media (max-width: 1232px) and (min-width: 792px)': {
      width: {
        sm: 'calc((100vw - 68px) / 2)'
      }
    },
    width: {
      lg: '369px',
      xl: '410px'
    }
  },
  header: {
    height: '31px',
    padding: {xs: '7px 8px 2px', sm: '7px 16px 2px'},
    '@media (max-width: 791px)': {
      width: {
        xs: 'min(calc(100vw - 77px), 349px)',
        sm: '333px'
      }
    },
    '@media (max-width: 888px) and (min-width: 792px)': {
      width: {
        sm: 'min(calc((100vw - 222px) / 2), 333px)'
      }
    },
    '@media (max-width: 1232px) and (min-width: 889px)': {
      width: {
        md: '333px'
      }
    },
    width: {
      lg: '292px',
      xl: '306px'
    }
  },
  cardMedia: {
    height: '230px',
    width: {xs: '45px', xl: '72px'}
  },
  divider: {
    backgroundColor: '#C3C1CA',
    borderBottomWidth: '1px',
    margin: {xs: '0 8px', sm: '0 16px'}
  },
  moreButton: {
    paddingLeft: '0px'
  },
  dividerColor: {
    [EQuarter.Q1]: {background: '#471FC7'},
    [EQuarter.Q2]: {background: '#DBA310', color: '#0F072A'},
    [EQuarter.Q3]: {background: '#2088C7'},
    [EQuarter.Q4]: {background: '#4EAF81'}
  }
}
