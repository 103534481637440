import {type BreakpointsOptions} from '@mui/material/styles'

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 568,
    md: 800,
    lg: 1024,
    xl: 1440
  }
}

export default breakpoints
