export const MuiStepLabel = {
  styleOverrides: {
    label: {
      fontFamily: 'noto_sansregular',
      fontSize: '1.3rem',
      fontWeight: '400',
      textTransform: 'uppercase' as const,
      lineHeight: '150%'
    }
  }
}
