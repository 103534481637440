export const MuiPopover = {
  styleOverrides: {
    root: {
      backgroundColor: 'none',
      '> .MuiBackdrop-root': {
        background: 'unset'
      }
    },
    paper: {
      borderRadius: '8px'
    }
  }
}
