import {SvgIconProps, SvgIcon} from '@mui/material'
import React from 'react'

import theme from '../theme'

export const LinkedInIcon = (props: Omit<SvgIconProps, 'viewBox'>) => {
  return (
    <SvgIcon
      height="32"
      viewBox="0 0 33 32"
      width="33"
      {...props}
      sx={{
        fontSize: 32,
        fill: theme.palette.primary.dark,
        opacity: '90%',
        '&:hover': {
          opacity: '70%'
        }
      }}>
      <rect fill={theme.palette.primary.dark} height="32" rx="16" width="32" x="0.4" y="0.3" />
      <rect fill="white" height="17" width="16" x="8.3" y="7.7" />
      <path d="M23.5825 23.2767H20.6007V18.653C20.6007 17.5505 20.5809 16.1312 19.0499 16.1312C17.4968 16.1312 17.2592 17.3324 17.2592 18.5728V23.2764H14.2774V13.7683H17.1399V15.0677H17.18C17.4665 14.5827 17.8805 14.1837 18.3778 13.9133C18.8752 13.6428 19.4373 13.511 20.0044 13.5318C23.0266 13.5318 23.5838 15.5001 23.5838 18.0607L23.5825 23.2767ZM10.9129 12.4686C10.5707 12.4687 10.2361 12.3683 9.95151 12.18C9.66692 11.9918 9.4451 11.7243 9.31407 11.4112C9.18305 11.0982 9.14871 10.7537 9.21542 10.4213C9.28212 10.089 9.44688 9.78365 9.68883 9.54399C9.93079 9.30433 10.2391 9.1411 10.5747 9.07493C10.9104 9.00876 11.2583 9.04263 11.5745 9.17225C11.8907 9.30187 12.161 9.52143 12.3512 9.80315C12.5414 10.0849 12.6429 10.4161 12.643 10.755C12.643 10.98 12.5983 11.2028 12.5114 11.4107C12.4245 11.6185 12.2971 11.8074 12.1364 11.9666C11.9758 12.1257 11.785 12.2519 11.5751 12.3381C11.3652 12.4242 11.1402 12.4686 10.9129 12.4686ZM12.4038 23.2767H9.41891V13.7683H12.4038V23.2767ZM25.0691 6.30019H7.92056C7.53133 6.29584 7.15627 6.44467 6.87782 6.71398C6.59936 6.98329 6.4403 7.35104 6.43555 7.73642V24.7864C6.44013 25.172 6.59911 25.54 6.87755 25.8096C7.15599 26.0792 7.53114 26.2283 7.92056 26.2242H25.0691C25.4593 26.229 25.8355 26.0803 26.115 25.8108C26.3946 25.5412 26.5546 25.1728 26.56 24.7864V7.73519C26.5544 7.34904 26.3943 6.98087 26.1147 6.71157C25.8352 6.44227 25.4591 6.29386 25.0691 6.29896" />
    </SvgIcon>
  )
}
