import {type Theme} from '@mui/material'

interface IStyleOverrideProps {
  theme: Theme
}

export const MuiTab = {
  styleOverrides: {
    root: ({theme}: IStyleOverrideProps) => ({
      opacity: 1,
      fontSize: '1.8rem',
      color: `${theme.palette.text.primary}`,
      display: 'block',
      borderBottom: '2px solid transparent',
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`
      }
    })
  }
}
