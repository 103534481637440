export default {
  accordionSummaryContainer: {
    justifyContent: 'left',
    padding: '0',
    flexDirection: 'row-reverse'
  },
  accordionContainer: (open: boolean) => ({
    boxShadow: 'none',
    margin: open ? '20px 0 8px' : '0px',
    '&:before': {
      display: 'none'
    }
  }),
  accordionTitle: {
    fontSize: '2.2rem',
    marginBottom: '0'
  },
  accordionDetailsContainer: {
    marginBottom: '20px',
    padding: '0'
  },
  divider: {
    backgroundColor: '#C3C1CA',
    borderBottomWidth: '0.01px'
  }
}
