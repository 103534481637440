interface IInternalErrorCssProps {
  color: string
}
interface IStyleProps {
  tryAgain: Record<string, any>
}

export const dynamicStyles = ({color}: IInternalErrorCssProps): IStyleProps => {
  return {
    tryAgain: {
      color
    }
  }
}

export const styles = {
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(var(--doc-height) - 80px)'
  }
}
