import {type PaletteOptions} from '@mui/material/styles'

const palette: PaletteOptions = {
  primary: {
    main: '#EE6352',
    dark: '#DD4C3A',
    light: '#F18274'
  },
  secondary: {
    main: '#291274',
    dark: '#0F072A',
    light: '#471FC7'
  },
  success: {
    main: '#59CD90',
    dark: '#3CB877',
    light: '#7AD7A6'
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350'
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800'
  },
  text: {
    primary: '#0F072A',
    secondary: '#6F6A7F',
    disabled: '#C3C1CA'
  }
}

export default palette
