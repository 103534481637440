/** @jsxImportSource @emotion/react */
import {Button, Stack, Typography, Unstable_Grid2 as Grid, Box} from '@mui/material'
import {Cross as Hamburger} from 'hamburger-react'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FeatureFlags, type IFeatureFlagsContext} from '../../contexts/featureFlags'
import {ETrackedEvents} from '../../types'
import {trackEvent} from '../../utils/mixpanel'
import ContactUsDrawer from '../contactUsForm/contactUsDrawer'
import DemoCalendarDrawer from '../demoCalendar/demoCalendar'
import LanguageMenu from '../languageMenu/languageMenu'
import MobileMenuDrawer from '../mobileMenuDrawer/mobileMenuDrawer'

import styles from './styles'

interface IHeaderProps {
  title: string
}

const Header = ({title}: IHeaderProps): JSX.Element => {
  const {t} = useTranslation()
  const [openContact, setOpenContact] = useState<boolean>(false)
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false)
  const features = useContext<IFeatureFlagsContext>(FeatureFlags)

  return (
    <>
      <Stack alignItems="center" component="header" direction="row" justifyContent="space-between" sx={styles.root}>
        <Typography sx={styles.title} variant="h2">
          {title}
        </Typography>
        <Grid container alignItems={'center'} spacing={1}>
          <Grid container spacing={1} sx={styles.rightMenu}>
            <Grid>
              <LanguageMenu
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                color="primary"
                margin="8px"
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                variant="outlined"
              />
            </Grid>
            <Grid>
              <Button
                sx={styles.bookDemoButton}
                variant="outlined"
                onClick={() => {
                  setOpenContact(true)
                  trackEvent(ETrackedEvents.ButtonClicked, {
                    Type: 'Book a demo'
                  })
                }}>
                {t('shared:header.bookDemo')}
              </Button>
            </Grid>
          </Grid>
          <Stack alignItems="center" direction="row">
            <Grid sx={styles.burgerIcon}>
              <Box component="span">
                <Hamburger
                  direction="right"
                  size={20}
                  toggled={toggleDrawer}
                  onToggle={() => setToggleDrawer(!toggleDrawer)}
                />
              </Box>
            </Grid>
          </Stack>
        </Grid>
      </Stack>
      <MobileMenuDrawer
        open={toggleDrawer}
        openContactForm={() => setOpenContact(true)}
        onClose={() => setToggleDrawer(false)}
      />
      {features.isHubspotCalendarEnabled ? (
        <DemoCalendarDrawer open={openContact} onClose={() => setOpenContact(false)} />
      ) : (
        <ContactUsDrawer open={openContact} onClose={() => setOpenContact(false)} />
      )}
    </>
  )
}

export default Header
