export default {
  root: {
    height: '72px',
    padding: '24px',
    width: '212px'
  },
  text: {
    fontSize: '1.2rem',
    textTransform: 'capitalize'
  }
}
