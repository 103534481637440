/** @jsxImportSource @emotion/react */
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatDate} from '../../utils/formatDate'

import styles from './styles'

interface IModelTrainingProps {
  coverage: string
  endDate?: string
  numberOfEnrichments: number
  numberOfUniqueCRMRecordsTrained: number
  startDate?: string
}

export const ModelTraining = ({
  coverage,
  endDate,
  numberOfEnrichments,
  numberOfUniqueCRMRecordsTrained,
  startDate
}: IModelTrainingProps) => {
  const {t} = useTranslation()
  return (
    <div css={styles.modelContainer}>
      <Typography sx={styles.sectitleText} variant="subtitle2">
        {t('insightCatalogue:insightsDetail.modelTraining.title')}
      </Typography>
      <Typography sx={styles.smallText} variant="caption">
        {t('insightCatalogue:insightsDetail.modelTraining.dataTimeFrame')}
        <Box component="span" sx={styles.highlightedText}>
          {formatDate(startDate)}
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
          {` ${t('shared:to')} `}
          {formatDate(endDate)}
        </Box>
      </Typography>
      <Typography sx={styles.smallText} variant="caption">
        {t('insightCatalogue:insightsDetail.modelTraining.coverage')}
        <Box component="span" sx={styles.highlightedText}>
          {coverage}
        </Box>
      </Typography>
      <Typography sx={styles.smallText} variant="caption">
        {t('insightCatalogue:insightsDetail.modelTraining.numUniqueCRM')}
        <Box component="span" sx={styles.highlightedText}>
          {numberOfUniqueCRMRecordsTrained}
        </Box>
      </Typography>
      <Typography sx={styles.smallText} variant="caption">
        {t('insightCatalogue:insightsDetail.modelTraining.numCRM')}
        <Box component="span" sx={styles.highlightedText}>
          {numberOfEnrichments}
        </Box>
      </Typography>
    </div>
  )
}
