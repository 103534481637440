import React, {createContext, useContext, useReducer} from 'react'

export const InsightsRefreshContext = createContext(false)
export const InsightsRefreshDispatchContext = createContext(
  null as unknown as ReturnType<typeof useInsightsRefreshDispatch>
)

interface IInsightsRefreshProviderProps {
  children?: React.ReactNode
}

export const InsightsRefreshProvider = ({children}: IInsightsRefreshProviderProps): JSX.Element => {
  const [shouldRefresh, dispatch] = useReducer(insightsRefreshReducer, false)

  return (
    <InsightsRefreshContext.Provider value={shouldRefresh}>
      <InsightsRefreshDispatchContext.Provider value={dispatch}>{children}</InsightsRefreshDispatchContext.Provider>
    </InsightsRefreshContext.Provider>
  )
}

export function useInsightsRefreshDispatch(): React.Dispatch<any> {
  return useContext(InsightsRefreshDispatchContext)
}

interface IInsightsRefresAction {
  type: EInsightsRefresActionType
}

export enum EInsightsRefresActionType {
  Refresh = 'refresh',
  Updated = 'updated'
}

const insightsRefreshReducer = (prevState: boolean, action: IInsightsRefresAction): boolean => {
  switch (action.type) {
    case EInsightsRefresActionType.Refresh: {
      return true
    }
    case EInsightsRefresActionType.Updated: {
      return false
    }
    default: {
      throw Error('Unknown action type')
    }
  }
}
