import {type Theme} from '@mui/material'

interface IStyleOverrideProps {
  theme: Theme
}

export const MuiTabs = {
  styleOverrides: {
    root: ({theme}: IStyleOverrideProps) => ({
      '& .Mui-selected': {
        color: theme.palette.primary.main
      }
    }),
    indicator: ({theme}: IStyleOverrideProps) => ({
      backgroundColor: theme.palette.primary.main
    })
  }
}
