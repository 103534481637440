export default {
  drawerContainer: {
    overflow: 'auto',
    padding: {xs: '0px 16px', sm: '0px 50px 35px'},
    textAlign: 'center' as const
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '26px 0px 10px'
  },
  closeButton: {
    color: {xs: 'white', lg: 'inherit'},
    fontSize: '1.8rem',
    position: {xs: 'absolute', lg: 'inherit'},
    right: {xs: '26px', lg: 'inherit'},
    top: {xs: '-35px', lg: 'inherit'}
  },
  closeFormButton: {
    color: {xs: 'black', lg: 'inherit'},
    fontSize: '1.8rem',
    position: {xs: 'absolute', lg: 'inherit'},
    right: {xs: '0', lg: 'inherit'},
    top: {xs: '15px', lg: 'inherit'},
    transform: 'rotate(180deg)',
    display: {xs: 'inherit', lg: 'none'}
  },
  closeIcon: {
    fontSize: '1.2rem!important',
    transform: 'rotate(90deg)'
  },
  title: {
    marginTop: {xs: '40px', lg: 'inherit'},
    marginBottom: '17px',
    textAlign: 'center'
  },
  drawerBackdrop: {
    backgroundColor: 'rgba(15,7,42,0.9)'
  },
  drawerPaper: {
    height: 'calc(var(--doc-height) - 80px)',
    maxWidth: '1480px',
    overflow: 'initial',
    width: {xs: '100%', lg: 'calc(100% - 60px)'}
  },
  drawerMobileMenuPaper: {
    height: 'calc(var(--doc-height) - 90px)',
    top: '50px',
    width: '100%'
  }
}
