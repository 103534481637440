const styles = {
  formContainer: {
    margin: 'auto',
    maxWidth: '500px',
    height: '500px'
  },
  iFrame: {
    border: 'none',
    width: '100%',
    height: '100%'
  }
}

export default styles
