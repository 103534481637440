export const MuiAvatar = {
  styleOverrides: {
    root: {
      fontFamily: '"BlenderPro_medium"',
      fontSize: '2.2rem',
      letterSpacing: '0.05em',
      lineHeight: '1'
    }
  }
}
