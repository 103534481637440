const styles = {
  demoTab: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%'
  },
  demoIframe: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  },
  tabsTitle: {
    fontFamily: 'BlenderPro_medium'
  },
  tabPanel: {
    padding: '0',
    overflow: 'auto',
    maxHeight: 420,
    borderRadius: '0 0 16px 16px'
  },
  reportImg: {
    borderRadius: '0 0 16px 16px',
    verticalAlign: 'middle',
    width: '100%'
  },
  tableTitleContainer: {
    margin: '16px'
  },
  tableTitle: {
    fontSize: '1.8rem'
  }
}

export default styles
