/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IBrand } from './IBrand';
import {
    IBrandFromJSON,
    IBrandFromJSONTyped,
    IBrandToJSON,
} from './IBrand';
import type { InsightDescriptionSectionDto } from './InsightDescriptionSectionDto';
import {
    InsightDescriptionSectionDtoFromJSON,
    InsightDescriptionSectionDtoFromJSONTyped,
    InsightDescriptionSectionDtoToJSON,
} from './InsightDescriptionSectionDto';
import type { InsightMetadataDtoDataTimeFrame } from './InsightMetadataDtoDataTimeFrame';
import {
    InsightMetadataDtoDataTimeFrameFromJSON,
    InsightMetadataDtoDataTimeFrameFromJSONTyped,
    InsightMetadataDtoDataTimeFrameToJSON,
} from './InsightMetadataDtoDataTimeFrame';

/**
 * 
 * @export
 * @interface InsightMetadataDto
 */
export interface InsightMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    detailedDescription: string;
    /**
     * 
     * @type {Array<InsightDescriptionSectionDto>}
     * @memberof InsightMetadataDto
     */
    descriptionSections: Array<InsightDescriptionSectionDto>;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    createdOn: string;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    pipelineVersion: string;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    insightMaker: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsightMetadataDto
     */
    industry: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    dashboardUrl?: string;
    /**
     * 
     * @type {InsightMetadataDtoDataTimeFrame}
     * @memberof InsightMetadataDto
     */
    dataTimeFrame?: InsightMetadataDtoDataTimeFrame;
    /**
     * 
     * @type {string}
     * @memberof InsightMetadataDto
     */
    coverage?: string;
    /**
     * 
     * @type {number}
     * @memberof InsightMetadataDto
     */
    numberOfUniqueCRMRecordsTrained?: number;
    /**
     * 
     * @type {number}
     * @memberof InsightMetadataDto
     */
    numberOfEnrichments?: number;
    /**
     * 
     * @type {object}
     * @memberof InsightMetadataDto
     */
    features?: object;
    /**
     * 
     * @type {Array<IBrand>}
     * @memberof InsightMetadataDto
     */
    brands?: Array<IBrand>;
}

/**
 * Check if a given object implements the InsightMetadataDto interface.
 */
export function instanceOfInsightMetadataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "detailedDescription" in value;
    isInstance = isInstance && "descriptionSections" in value;
    isInstance = isInstance && "createdOn" in value;
    isInstance = isInstance && "pipelineVersion" in value;
    isInstance = isInstance && "insightMaker" in value;
    isInstance = isInstance && "industry" in value;

    return isInstance;
}

export function InsightMetadataDtoFromJSON(json: any): InsightMetadataDto {
    return InsightMetadataDtoFromJSONTyped(json, false);
}

export function InsightMetadataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightMetadataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'detailedDescription': json['detailedDescription'],
        'descriptionSections': ((json['descriptionSections'] as Array<any>).map(InsightDescriptionSectionDtoFromJSON)),
        'createdOn': json['createdOn'],
        'pipelineVersion': json['pipelineVersion'],
        'insightMaker': json['insightMaker'],
        'industry': json['industry'],
        'dashboardUrl': !exists(json, 'dashboardUrl') ? undefined : json['dashboardUrl'],
        'dataTimeFrame': !exists(json, 'dataTimeFrame') ? undefined : InsightMetadataDtoDataTimeFrameFromJSON(json['dataTimeFrame']),
        'coverage': !exists(json, 'coverage') ? undefined : json['coverage'],
        'numberOfUniqueCRMRecordsTrained': !exists(json, 'numberOfUniqueCRMRecordsTrained') ? undefined : json['numberOfUniqueCRMRecordsTrained'],
        'numberOfEnrichments': !exists(json, 'numberOfEnrichments') ? undefined : json['numberOfEnrichments'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'brands': !exists(json, 'brands') ? undefined : ((json['brands'] as Array<any>).map(IBrandFromJSON)),
    };
}

export function InsightMetadataDtoToJSON(value?: InsightMetadataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'detailedDescription': value.detailedDescription,
        'descriptionSections': ((value.descriptionSections as Array<any>).map(InsightDescriptionSectionDtoToJSON)),
        'createdOn': value.createdOn,
        'pipelineVersion': value.pipelineVersion,
        'insightMaker': value.insightMaker,
        'industry': value.industry,
        'dashboardUrl': value.dashboardUrl,
        'dataTimeFrame': InsightMetadataDtoDataTimeFrameToJSON(value.dataTimeFrame),
        'coverage': value.coverage,
        'numberOfUniqueCRMRecordsTrained': value.numberOfUniqueCRMRecordsTrained,
        'numberOfEnrichments': value.numberOfEnrichments,
        'features': value.features,
        'brands': value.brands === undefined ? undefined : ((value.brands as Array<any>).map(IBrandToJSON)),
    };
}

