import {type Theme} from '@mui/material'

interface IStyleOverrideProps {
  theme: Theme
}
export const MuiTypography = {
  styleOverrides: {
    h1: ({theme}: IStyleOverrideProps) => ({
      [theme.breakpoints.down('md')]: {
        fontSize: '3.6rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.8rem'
      }
    }),
    h4: ({theme}: IStyleOverrideProps) => ({
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem'
      }
    })
  }
}
