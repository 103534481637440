declare global {
  interface Window {
    env: any
    _hsp: any
  }
}

type EnvType = {
  REACT_APP_INSIGHT_STORE_BACKEND_URL: string
  REACT_APP_MIXPANEL_PROXY_DOMAIN: string
  REACT_APP_MIXPANEL_PROJECT_TOKEN: string
  REACT_APP_HUBSPOT_EMBED_CALENDAR_URL_EN: string
  REACT_APP_HUBSPOT_EMBED_CALENDAR_URL_ES: string

  // FEATURE FLAGS
  REACT_APP_IS_HUBSPOT_CALENDAR_ENABLED: boolean
  REACT_APP_HUBSPOT_CONTACT_US_FORM_ID_EN: string
  REACT_APP_HUBSPOT_CONTACT_US_FORM_ID_ES: string
}

export const env: EnvType = {
  ...process.env,
  ...window.env
}
