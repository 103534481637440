/* tslint:disable */
/* eslint-disable */
/**
 * insights-store-backend
 * insights-store-backend API definition
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScmDto } from './ScmDto';
import {
    ScmDtoFromJSON,
    ScmDtoFromJSONTyped,
    ScmDtoToJSON,
} from './ScmDto';

/**
 * 
 * @export
 * @interface ManifestDto
 */
export interface ManifestDto {
    /**
     * 
     * @type {string}
     * @memberof ManifestDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ManifestDto
     */
    version: string;
    /**
     * 
     * @type {ScmDto}
     * @memberof ManifestDto
     */
    scm: ScmDto;
    /**
     * 
     * @type {string}
     * @memberof ManifestDto
     */
    timestamp: string;
}

/**
 * Check if a given object implements the ManifestDto interface.
 */
export function instanceOfManifestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "scm" in value;
    isInstance = isInstance && "timestamp" in value;

    return isInstance;
}

export function ManifestDtoFromJSON(json: any): ManifestDto {
    return ManifestDtoFromJSONTyped(json, false);
}

export function ManifestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManifestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'version': json['version'],
        'scm': ScmDtoFromJSON(json['scm']),
        'timestamp': json['timestamp'],
    };
}

export function ManifestDtoToJSON(value?: ManifestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'version': value.version,
        'scm': ScmDtoToJSON(value.scm),
        'timestamp': value.timestamp,
    };
}

