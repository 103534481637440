import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import './styles.css'

interface IMarkdownViewerProps {
  doc: string
}

const MarkdownViewer = ({doc}: IMarkdownViewerProps): JSX.Element => (
  <ReactMarkdown className="root" remarkPlugins={[remarkGfm]}>
    {doc}
  </ReactMarkdown>
)

export default MarkdownViewer
