import {Button, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {SUPPORTED_LANGUAGES} from '../../../config/constVariables'
import {ETrackedEvents} from '../../../types'
import {trackEvent} from '../../../utils/mixpanel'
import {redirectLng} from '../../../utils/redirectLng'

import styles from './styles'

interface ILanguagePopoverProps {
  selected: string
  onSelect: () => void
}

const supportedLngs = Object.values(SUPPORTED_LANGUAGES).map((value) => value.isoCode)

const nativeNames = Object.values(SUPPORTED_LANGUAGES).map((value) => value.nativeName)

const LanguagePopover = ({selected, onSelect}: ILanguagePopoverProps): JSX.Element => {
  const {i18n, t} = useTranslation()
  const navigate = useNavigate()

  const changeLanguage = (lng: string): void => {
    i18n
      .changeLanguage(lng)
      .then(() => {
        navigate(redirectLng(lng))
      })
      .catch(() => navigate(redirectLng(i18n.language)))
  }

  return (
    <Stack direction="column" justifyContent="space-between" sx={styles.root}>
      <Typography sx={styles.text}>{t('shared:language')}</Typography>
      <Stack direction="row" justifyContent="space-between">
        {nativeNames.map((lang: string, index: number) => (
          <Button
            key={lang}
            variant={selected === supportedLngs[index] ? 'contained' : 'outlined'}
            onClick={() => {
              changeLanguage(supportedLngs[index])
              onSelect()
              trackEvent(ETrackedEvents.ButtonClicked, {
                Type: 'Language selector',
                Lang: supportedLngs[index]
              })
            }}>
            {lang}
          </Button>
        ))}
      </Stack>
    </Stack>
  )
}

export default LanguagePopover
