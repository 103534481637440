export default {
  root: {
    background: '#f6f5f7',
    borderBottom: '1px solid #C3C1CA',
    height: '80px',
    padding: {
      xs: '0 30px',
      sm: '0 50px',
      md: '0 24px',
      lg: '0 1.6%',
      xl: '0 2.8%'
    },
    '@media (max-width: 1440px) and (min-width: 1397px)': {
      padding: {
        lg: '0 max(24px, calc((100% - 1274px)/2))'
      }
    },
    position: 'sticky' as const,
    top: 0,
    zIndex: 3
  },
  title: {
    fontFamily: 'BlenderPro_bold',
    margin: 0
  },
  sectionTitle: {
    fontFamily: 'BlenderPro_medium',
    fontSize: '12px'
  },
  bookDemoButton: {
    minWidth: '170px',
    width: 'fit-content'
  },
  rightMenu: {
    display: {xs: 'none', lg: 'flex'}
  },
  skeletonText: {
    width: {xs: '280px', sm: '450px'}
  },
  burgerIcon: {
    display: {xs: 'flex', lg: 'none'},
    padding: '0'
  },
  mobileMenuContainer: {
    marginInline: 'auto',
    maxWidth: '540px'
  }
}
