/** @jsxImportSource @emotion/react */
import i18n from 'i18next'
import React from 'react'
import {Outlet, useParams, Navigate} from 'react-router-dom'

import {SUPPORTED_LANGUAGES} from '../../config/constVariables'
import {redirectLng} from '../../utils/redirectLng'

const supportedLngs = Object.values(SUPPORTED_LANGUAGES).map((value) => value.isoCode)
const reg = new RegExp('^(' + supportedLngs.join('|') + ')$')

const LanguageRouter = (): JSX.Element => {
  const {lng} = useParams()

  const languageMatch = lng?.match(reg)

  if (languageMatch === null) {
    return <Navigate replace={true} to={redirectLng(i18n.language)} />
  }

  return <Outlet />
}

export default LanguageRouter
