/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box'
import React from 'react'

import styles from './styles'

interface INavbarItem {
  iconClass: string
  label: string
}

interface IProps {
  item: INavbarItem
  selected: boolean
  height: string
  onClick: () => void
}

const NavbarItem = ({item, selected, height, onClick}: IProps): JSX.Element => (
  <Box sx={[styles.root(height), selected ? styles.rootSelected : styles.rootNotSelected]} onClick={onClick}>
    <i className={item.iconClass} css={styles.icon} />
    <p css={styles.label}>{item.label}</p>
  </Box>
)

export default NavbarItem
