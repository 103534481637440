interface IAuthScreenCssProps {
  color: string
}
interface IStyleProps {
  link: Record<string, any>
}

export const dynamicStyles = ({color}: IAuthScreenCssProps): IStyleProps => {
  return {
    link: {
      color,
      fontSize: '13px',
      marginRight: '50px'
    }
  }
}

export default {
  bannerContainer: {
    background: 'linear-gradient(78.25deg, #EE6352 0.37%, #DC6154 24.76%, #F18275 53.3%, #DC6154 79.76%, #EE6352 100%)',
    color: 'white',
    margin: {sm: '0', md: '0 24px', lg: '0 1.6%', xl: '0 2.8%'},
    '@media (max-width: 1440px) and (min-width: 1397px)': {
      margin: {
        lg: '0 max(24px, calc((100% - 1274px)/2))'
      }
    },
    padding: {xs: '30px', sm: '30px 50px'}
  },
  leftContainer: {
    alignSelf: 'center'
  },
  leftText: {
    maxWidth: '488px'
  },
  rightContainer: {
    paddingLeft: {sm: '32px', md: '40px', xl: '20px'}
  },
  rightText: {
    fontSize: '2rem',
    fontWeight: '400',
    lineHeight: '2rem',
    maxWidth: '421px',
    textTransform: 'none'
  },
  list: {
    marginTop: '0px',
    paddingLeft: '15px'
  },
  listElement: {
    marginBottom: '8px'
  },
  contactIcon: {
    fontSize: '1.2rem!important',
    height: '11px',
    transform: 'rotate(90deg)',
    width: '12px'
  }
}
